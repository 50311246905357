import { useUsername } from '@mmw/redux-store-auth-api-login-by-password/hooks';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { UserLoginConfiguration } from '@mmw/services-auth-api-authentication/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { recoverPasswordStartAction } from '../actions';

const useRecoverPassword = (): F.Function<
  [config?: UserLoginConfiguration]
> => {
  const language = useLanguage();
  const dispatch = useDispatch();
  const userId = useUsername();
  return useCallback(
    config => {
      const id = config?.userid || userId;
      if (id) dispatch(recoverPasswordStartAction(id, language));
    },
    [dispatch, language, userId],
  );
};

export default useRecoverPassword;
