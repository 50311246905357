import { Api } from '@mmw/api-auth';
import { CacheInterface } from '@mmw/common-cache';
import { ClientFingerprint } from '@mmw/common-client-fingerprint';
import { StoreInterface } from '@mmw/common-store';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { TokenParseResult } from '@mmw/services-auth-api-token-parser/types';
import { U } from 'ts-toolbelt';

export type AuthenticationRequest = {
  username: string;
  password: string;
  applicationId?: U.Nullable<string>;
  deviceId?: string;
  recaptchaResponse?: U.Nullable<string>;
  recaptchaType?: U.Nullable<RecaptchaType>;
  scopeName?: SecurityScopeNames;
};

export type GoogleAuthenticationRequest = {
  googleJwtToken: string;
  applicationId?: U.Nullable<string>;
  deviceId?: string;
  recaptchaResponse?: U.Nullable<string>;
  recaptchaType?: U.Nullable<RecaptchaType>;
  applicationBaseUrl?: string;
  applicationPath?: string;
};

export type UnblockRequest = {
  username: string;
  applicationId: U.Nullable<string>;
  deviceId?: string;
  recaptchaResponse: U.Nullable<string>;
  recaptchaType?: U.Nullable<RecaptchaType>;
  scopeName?: SecurityScopeNames;
  applicationBaseUrl?: U.Nullable<string>;
  applicationContextPath?: U.Nullable<string>;
  redirectUrl?: U.Nullable<string>;
};

export class BaseEmailConfiguration {
  subjectResourcekey: string;

  bodyResourcekey: string;
}

export class EmailConfiguration extends BaseEmailConfiguration {
  bundleName: string;

  language: string;

  salesOrgBrandID?: number;
}

export class SmsConfiguration {
  bundleName: string;

  language: string;

  resourcekey: string;
}

export enum OperationsEnum {
  ORGUNIT_SIGNUP = 'orgunit-signup',
  CONSUMER_LEVEL4_SIGNUP = 'consumer-level4-signup',
  CONSUMER_LEVEL2_SIGNUP = 'consumer-level2-signup',
  CONSUMER_CHANGE_PASSWORD = 'consumer-change-password',
  CONSUMER_SAVE_BANK_ACCOUNT = 'consumer-save-bank-account',
  AGREEMENT_CONFIRMATION = 'agreement-confirmation',
  PHONE_REGISTRATION = 'phone-registration',
  USER_CHANGE_PASSWORD = 'user-change-password',
}

export type TwoFactorFlowError =
  | 'BAD_CREDENTIALS'
  | 'INVALID_OPERATION'
  | 'INVALID_CAPTCHA'
  | 'INVALID_APPLICATION'
  | 'UNKNOWN'
  | 'TIME_BLOCKED';

export type TwoFactorAuthResult = {
  success: boolean;
  error: TwoFactorFlowError;
  accessToken: string;
};

export type RequestEmailVerificationRequest = {
  email: string;
  operationId: OperationsEnum;
  applicationId?: string;
  deviceId?: string;
  recaptchaResponse?: U.Nullable<string>;
  emailConfiguration?: EmailConfiguration;
  recaptchaType: string;
};

export type RequestPhonelVerificationRequest = {
  phone: string;
  country: string;
  email: string;
  operationId: OperationsEnum;
  applicationId?: string;
  smsConfiguration?: SmsConfiguration;
  deviceId?: string;
  recaptchaResponse?: U.Nullable<string>;
  recaptchaType: string;
};

export type RecaptchaType = 'V2' | 'V3';

export type VerifyEmailRequest = {
  email: string;
  tan: string;
  operationId: OperationsEnum;
  applicationId?: string;
  deviceId?: string;
  recaptchaResponse?: U.Nullable<string>;
  recaptchaType: RecaptchaType;
};

export type VerifyPhoneRequest = {
  phone: string | number;
  country: string;
  tan: string;
  operationId: OperationsEnum;
  email: string;
  applicationId?: string;
  deviceId?: string;
  recaptchaResponse?: U.Nullable<string>;
  recaptchaType: RecaptchaType;
};

export type RoleStatus = {
  consumer: boolean;
  trader: boolean;
  manufacturer: boolean;
  mmw: boolean;
  solar: boolean;
};

export type AuthenticationResponse = {
  success: boolean;
  error?:
    | 'CAPTCHA_BLOCKED'
    | 'EMAIL_BLOCKED'
    | 'BAD_CREDENTIALS'
    | 'INVALID_APPLICATION'
    | 'MISSING_AUTHORITY'
    | 'UNKNOWN';
  accessToken?: string;
  refreshToken?: string;
  roleStatus?: RoleStatus;
  redirectUrl?: string;
  onLoginUrl?: string;
  onLogoutUrl?: string;
};

export type UnblockResponse = {
  success: boolean;
  error?: 'UNKNOWN' | 'CODE' | 'REFERER' | null;
};

export type ValidateUserByEmailResult = {
  success: boolean;
  error: U.Nullable<'USER_DUPLICATED' | 'USER_NOT_FOUND' | 'UNKNOWN_ERROR'>;
  userid: U.Nullable<string>;
  scopeName?: U.Nullable<SecurityScopeNames>;
};

export type AuthenticationServiceOptions = {
  api?: Api;
  clientFingerprint: ClientFingerprint;
  cache: CacheInterface | StoreInterface;
  applicationId: string;
  accessTokenHeaderName?: U.Nullable<string>;
  tanEmailConfiguration?: U.Nullable<EmailConfiguration>;
  unblockAccountEmailConfiguration?: U.Nullable<EmailConfiguration>;
  requestEmailVerificationEmailConfiguration?: U.Nullable<EmailConfiguration>;
  requestPhoneVerificationSmsConfiguration?: U.Nullable<SmsConfiguration>;
  recoverPasswordEmailConfiguration?: U.Nullable<EmailConfiguration>;
  recoverUsernameEmailConfiguration?: U.Nullable<EmailConfiguration>;
  allowedRoles?: U.Nullable<string[]>;
  allowedRoleStatus?: U.Nullable<RoleStatus>;
  allowedSalesOrgBrands?: U.Nullable<SalesOrgBrand[]>;
  allowedScopeNames?: U.Nullable<SecurityScopeNames[]>;
  requiredPermission?: U.Nullable<string>;
  defaultScopeName?: SecurityScopeNames;
};

export type RefreshAuthenticationResponse = {
  success: boolean;
  accessToken?: string;
};

export type IsAuthenticatedOptions = {
  fetchApi?: boolean;
};

export type AuthenticationHeaders = Record<string, string>;

export type RequestTanRequest = {
  username: string;
  applicationId?: string;
  deviceId?: string;
  emailConfiguration?: EmailConfiguration;
  isReSend?: boolean;
};

export type TanRequestErrorCode =
  | 'INVALID_APPLICATION'
  | 'BAD_CREDENTIALS'
  | 'UNKNOWN';

export type OperationResult = {
  success: boolean;
  errorCode?: TanRequestErrorCode;
};

export type AuthenticationByTanRequest = {
  username: string;
  tan: string;
  applicationId?: string;
  deviceId?: string;
  recaptchaResponse?: U.Nullable<string>;
};

export type ValidateTokenResult = {
  success: boolean;
  error:
    | 'TOKEN_EXPIRED'
    | 'TOKEN_NOT_DATABASE'
    | 'TOKEN_CREDENTIALS_NOT_MATCHING'
    | 'TOKEN_UNSUPPORTED_CONFIGURATION'
    | 'TOKEN_INVALID_SIGNATURE'
    | 'UNKNOWN_ERROR'
    | 'TOKEN_NOT_INFORMED'
    | null;
  claims: TokenParseResult | null;
};

export type GenerateSSOTokenResult = {
  success: boolean;
  error:
    | 'USER_NOT_FOUND'
    | 'INVALID_APPLICATION'
    | 'UNKNOWN'
    | 'INVALID_SYSTEM_TOKEN'
    | 'INVALID_USER'
    | null;
  ssoToken: string | null;
  ttl: number | null;
};

export type UserLoginConfiguration = {
  found?: boolean;
  userid?: U.Nullable<string>;
  email: U.Nullable<string>;
  allowLoginByPassword?: boolean;
  allowLoginByTan?: boolean;
};

export enum SecurityScopeNames {
  DEFAULT_CONSUMERS = 'default-consumers',
  NON_CONSUMERS = 'non-consumers',
  NIKON_API_CONSUMERS = 'nikon-api-consumers',
  LEVEL_2_CONSUMERS = 'level-2-consumers',
  LEVEL_4_CONSUMERS = 'level-4-consumers',
  LEVEL_5_CONSUMERS = 'level-5-consumers',
  BSH_IMPORTER_CONSUMERS = 'bsh-importer-consumers',
}

export class SendAuthenticationEmailRequest {
  username: string;

  applicationBaseUrl?: U.Nullable<string>;

  applicationPath?: U.Nullable<string>;

  applicationContextPath?: U.Nullable<string>;

  applicationId?: string;

  scopeName?: SecurityScopeNames = SecurityScopeNames.NON_CONSUMERS;

  deviceId?: string;

  recaptchaResponse?: U.Nullable<string>;

  emailConfiguration: EmailConfiguration;

  recaptchaType?: string;
}

export const emptyUserLoginConfiguration = (): UserLoginConfiguration => ({
  found: false,
  userid: null,
  email: null,
  allowLoginByPassword: false,
  allowLoginByTan: false,
});

export enum AuthenticationAuthorities {
  PERM_MANUFACTURER_CLIENT_ONLINE_REPORTING = 'PERM_MANUFACTURER_CLIENT_ONLINE_REPORTING',
  PERM_MANUFACTURER_PRODUCTS_VIEW = 'PERM_WEBSERVICE_MANUF_PRODUCTS_VIEW',
  PERM_MANUFACTURER_CREATE_CAMPAIGN = 'PERM_WEBSERVICE_MANUF_CREATE_CAMPAIGN',
  PERM_MANUFACTURER_SOLAR_PROJECTS = 'PERM_WEBSERVICE_MANUF_SOLAR_PROJECTS',
  PERM_MANUFACTURER_ORGUNIT_BR_CONSUMER_UPDATE = 'PERM_WEBSERVICE_MANUF_ORGUNIT_BR_CONSUMER_UPDATE',
  PERM_MANUFACTURER_MARKETING_BUDGET_MANAGEMENT = 'PERM_WEBSERVICE_MANUF_MARKETING_BUDGET_MANAGEMENT',
  PERM_MANUF_ORGUNIT_BR_ADDRESS_UPDATE = 'PERM_WEBSERVICE_MANUF_ORGUNIT_BR_ADDRESS_UPDATE',
  PERM_MANUF_ORGUNIT_SEARCH = 'PERM_WEBSERVICE_MANUF_ORGUNIT_SEARCH',
  PERM_WEBSERVICE_MANUF_SEARCH_CAMPAIGN = 'PERM_WEBSERVICE_MANUF_SEARCH_CAMPAIGN',
  PERM_WEBSERVICE_MANUF_SOLAR_PROJECTS_READ_ONLY = 'PERM_WEBSERVICE_MANUF_SOLAR_PROJECTS_READ_ONLY',
  PERM_WEBSERVICE_MANUF_ORGUNIT_STORE_LOCATOR = 'PERM_WEBSERVICE_MANUF_ORGUNIT_STORE_LOCATOR',
  PERM_WEBSERVICE_RECGROUP_COIN_MANAGEMENT = 'PERM_WEBSERVICE_RECGROUP_COIN_MANAGEMENT',
  PERM_WEBSERVICE_MANUF_UPDATE_OWN_ORGUNIT = 'PERM_WEBSERVICE_MANUF_UPDATE_OWN_ORGUNIT',
  PERM_WEBSERVICE_MANUF_REGISTRATIONS_SEARCH = 'PERM_WEBSERVICE_MANUF_REGISTRATIONS_SEARCH',
  PERM_WEBSERVICE_MANUF_USERS_VIEW = 'PERM_WEBSERVICE_MANUF_USERS_VIEW',
  PERM_WEBSERVICE_MANUF_USERS_SAVE = 'PERM_WEBSERVICE_MANUF_USERS_SAVE',
  PERM_WEBSERVICE_MANUF_USERS_DELETE = 'PERM_WEBSERVICE_MANUF_USERS_DELETE',
  PERM_WEBSERVICE_MANUF_USERS_UPDATE = 'PERM_WEBSERVICE_MANUF_USERS_UPDATE',
  PERM_TRADER_PRODUCT_SEARCH = 'PERM_WEBSERVICE_TRADER_PRODUCT_SEARCH',
  PERM_TRADER_CONSUMERS = 'PERM_WEBSERVICE_TRADER_CONSUMERS',
  PERM_TRADER_SOLAR_PROJECTS = 'PERM_WEBSERVICE_TRADER_SOLAR_PROJECTS',
  PERM_TRADER_SOLAR_FLASHER = 'PERM_WEBSERVICE_TRADER_SOLAR_FLASHER',
  PERM_TRADER_SOLAR_DELIVERIES = 'PERM_WEBSERVICE_TRADER_SOLAR_DELIVERIES',
  PERM_TRADER_CAMPAIGNS = 'PERM_WEBSERVICE_TRADER_CAMPAIGNS',
  PERM_TRADER_ORGUNIT_DETAILS_EDIT = 'PERM_WEBSERVICE_TRADER_ORGUNIT_DETAILS_EDIT',
  PERM_TRADER_ORGUNIT_DETAILS_VIEW = 'PERM_WEBSERVICE_TRADER_ORGUNIT_DETAILS_VIEW',
  TRADER_ORGUNIT_DETAILS_EDIT = 'WEBSERVICE_TRADER_ORGUNIT_DETAILS_EDIT',
  PERM_TRADER_PROFILE = 'PERM_WEBSERVICE_TRADER_PROFILE',
  ROLE_SOLARDIST = 'ROLE_SOLARDIST',
  ROLE_SOLARMANUFCT = 'ROLE_SOLARMANUFCT',
  ROLE_SOLARSYSADMIN = 'ROLE_SOLARSYSADMIN',
  ROLE_SOLARUSER = 'ROLE_SOLARUSER',
  ROLE_SOLARADMIN = 'ROLE_SOLARADMIN',
  ROLE_SOLARFLASHER = 'ROLE_SOLARFLASHER',
  ROLE_USER = 'ROLE_USER',
  ROLE_RMANUFCT = 'ROLE_RMANUFCT',
}
