import { getFieldPaths } from '@mmw/utils-object-utils';

export type recoverResponse = {
  success: boolean;
};

export const RECOVER_PASSWORD_START =
  '@mmw/auth-api/recover-password/RECOVER_PASSWORD_START';
export const RECOVER_PASSWORD_SUCCESS =
  '@mmw/auth-api/recover-password/RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_ERROR =
  '@mmw/auth-api/recover-password/RECOVER_PASSWORD_ERROR';

export const CHANGE_PASSWORD_START =
  '@mmw/auth-api/recover-password/CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS =
  '@mmw/auth-api/recover-password/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR =
  '@mmw/auth-api/recover-password/CHANGE_PASSWORD_ERROR';

export const VALIDATE_CHANGE_PASSWORD_UUID_START =
  '@mmw/auth-api/recover-password/VALIDATE_CHANGE_PASSWORD_UUID_START';
export const VALIDATE_CHANGE_PASSWORD_UUID_SUCCESS =
  '@mmw/auth-api/recover-password/VALIDATE_CHANGE_PASSWORD_UUID_SUCCESS';
export const VALIDATE_CHANGE_PASSWORD_UUID_ERROR =
  '@mmw/auth-api/recover-password/VALIDATE_CHANGE_PASSWORD_UUID_ERROR';
export const RESET = '@mmw/auth-api/recover-password/RESET';
export class RecoverPasswordFormData {
  password = '';

  confirmPassword = '';
}

export const RECOVER_PASSWORD_FIELD_PATHS =
  getFieldPaths<RecoverPasswordFormData>(new RecoverPasswordFormData());

export type RecoverPasswordStartAction = {
  type: '@mmw/auth-api/recover-password/RECOVER_PASSWORD_START';
  payload: {
    userid: string;
    language: string;
  };
};

export type RecoverPasswordSuccessAction = {
  type: '@mmw/auth-api/recover-password/RECOVER_PASSWORD_SUCCESS';
  payload: recoverResponse;
};

export type RecoverPasswordErrorAction = {
  type: '@mmw/auth-api/recover-password/RECOVER_PASSWORD_ERROR';
  payload: {
    error: Error | null;
  };
};

export type ChangePasswordStartAction = {
  type: '@mmw/auth-api/recover-password/CHANGE_PASSWORD_START';
  payload: {
    uuid: string;
    password: string;
    language: string;
    onSuccess?: () => void;
  };
};

export type ChangePasswordSuccessAction = {
  type: '@mmw/auth-api/recover-password/CHANGE_PASSWORD_SUCCESS';
  payload: {
    userid: string;
  };
};

export type ChangePasswordErrorAction = {
  type: '@mmw/auth-api/recover-password/CHANGE_PASSWORD_ERROR';
  payload: {
    error: Error | null;
  };
};

export type ValidateChangePasswordUuidStartAction = {
  type: '@mmw/auth-api/recover-password/VALIDATE_CHANGE_PASSWORD_UUID_START';
  payload: {
    uuid: string;
  };
};

export type ValidateChangePasswordUuidSuccessAction = {
  type: '@mmw/auth-api/recover-password/VALIDATE_CHANGE_PASSWORD_UUID_SUCCESS';
  payload: {
    success: boolean;
  };
};

export type ValidateChangePasswordUuidErrorAction = {
  type: '@mmw/auth-api/recover-password/VALIDATE_CHANGE_PASSWORD_UUID_ERROR';
  payload: {
    error: Error | null;
  };
};

export type ResetAction = {
  type: '@mmw/auth-api/recover-password/RESET';
};

export type State = {
  isValidUuid: boolean;
  loading: boolean;
  error: Error | null;
  emailSentStatus: boolean;
};

export const NAMESPACE = 'recoverPassword';

export type RootState = {
  recoverPassword: State;
};

export type Action =
  | RecoverPasswordStartAction
  | RecoverPasswordSuccessAction
  | RecoverPasswordErrorAction
  | ChangePasswordStartAction
  | ChangePasswordSuccessAction
  | ChangePasswordErrorAction
  | ValidateChangePasswordUuidStartAction
  | ValidateChangePasswordUuidSuccessAction
  | ValidateChangePasswordUuidErrorAction
  | ResetAction;

export type Reducer = (state: State, action: Action) => State;
