import AuthCard from '@auth/view-form-components/AuthCard';
import Login, { LoginContentProps } from '@auth/view-login';
import { useNavigateToRoute } from '@lib/navigation/hooks';
import {
  useNavigateToNewAccount,
  useNavigateToRecoverPassword,
} from '@mmw/common-navigation/hooks';
import Routes from '@mmw/common-navigation/routes';
import { I18nShape } from '@mmw/constants-i18n';
import { SIGN_IN as SIGN_IN_I18N } from '@mmw/constants-i18n-authentication';
import { WELCOME } from '@mmw/constants-i18n-common';
import { getApplicationId } from '@mmw/contextual-config';
import Page from '@mmw/ui-page';
import { ResourcesContextProvider } from '@mmw/ui-providers-resources';
import { ReCaptchaV3ContextProvider } from '@recaptcha/v3';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

interface LoginPageProps extends LoginContentProps {
  contentStyle?: Style | Style[];
  customTitleI18n?: I18nShape;
  customDescI18n?: I18nShape;
  onLoginSuccess?: F.Function;
  onClickRecoverPass?: F.Function;
}

const style = css`
  justify-content: center;
`;

const LoginPage: React.FC<LoginPageProps> = ({
  contentStyle = style,
  customTitleI18n,
  customDescI18n,
  onLoginSuccess,
  onClickRecoverPass,
  ...props
}: LoginPageProps) => {
  const onSuccess = useNavigateToRoute(Routes.HOME, { disableState: true });
  const onClickRecover = useNavigateToRecoverPassword();
  const onClickNewAccount = useNavigateToNewAccount();
  const assets = useAssets();
  return (
    <Page
      backgroundImageUrl={assets?.loginBackgroundImage}
      backgroundSize="cover"
    >
      <Page.Content contentStyle={contentStyle}>
        <ReCaptchaV3ContextProvider>
          <UI.Container justify="center">
            <ResourcesContextProvider prefixKey={getApplicationId()}>
              <AuthCard
                titleI18n={customTitleI18n || WELCOME}
                descI18n={customDescI18n || SIGN_IN_I18N.INTRODUTCTION}
              >
                <Login
                  onSuccess={onLoginSuccess || onSuccess}
                  onClickNewAccount={onClickNewAccount}
                  onClickRecoverPass={onClickRecoverPass || onClickRecover}
                  {...props}
                />
              </AuthCard>
            </ResourcesContextProvider>
          </UI.Container>
        </ReCaptchaV3ContextProvider>
      </Page.Content>
    </Page>
  );
};

export default LoginPage;
