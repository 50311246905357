import { CookieConsent } from '@cookie-consent/core';
import useConfigLinks from '@mmw/clients-ui-hooks-use-legal-links';
import {
  FOOTER_LINKS,
  FOOTER_LINKS as FOOTER_LINKS_I18N,
} from '@mmw/constants-i18n-functionalities';
import { getClientVersion } from '@mmw/contextual-config';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToSM } from '@ui-system/media-query';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { useBoolean } from 'ahooks';
import * as React from 'react';
import { useCallback } from 'react';

// TODO: Hardcoded texts

const useContentContainerStyle = makeStyle(
  ({ mediaQueries }) => css`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 30px;
    max-width: 1200px;
    ${mediaQueries.md`
      max-width: 900px;
    `}
    ${mediaQueries.sm`
      max-width: auto;
    `}
  `,
);

const AppVersion: React.FC = () => {
  const version = getClientVersion();
  if (!version) return null;

  return (
    <UI.Container
      style={css`
        position: absolute;
        bottom: 15px;
        right: 15px;
      `}
    >
      <UI.Overline modifiers="primary, lowercase">v:</UI.Overline>
      <UI.Overline modifiers="primary">{version}</UI.Overline>
    </UI.Container>
  );
};

const Footer: React.FC = () => {
  const assets = useAssets();
  const navigate = useCallback((link: string | URL) => window.open(link), []);
  const isPhone = useIsMediaQueryUpToSM();
  const [
    cookiesVisible,
    { setTrue: showCookiesModal, setFalse: hideCookiesModal },
  ] = useBoolean();
  const contentContainerStyle = useContentContainerStyle();
  const { imprintLink, dataProtectionLink, gtcLink } = useConfigLinks();
  // const upToMd = useIsMediaQueryUpToMD();

  return (
    <UI.Container
      bg="basicLight"
      style={css`
        padding: ${isPhone ? '20px 60px' : '40px 100px'};
        position: relative;
      `}
      direction="column"
      justify="center"
      align="center"
      w="100%"
    >
      <UI.Image src={assets.mmwLogo} width="auto" height={60} />
      <UI.Container
        style={contentContainerStyle}
        modifiers="grid3col"
        responsive="upToSm.direction=column;upToSm.modifiers=grid1col;upToSm.m=4,0,0,0;upToSm.h=100%"
      >
        <UI.Container direction="column" responsive="upToSm.m=0,0,2,0;">
          <UI.Typography modifiers="primary, bold">
            marken mehrwert - brand added value AG
          </UI.Typography>
          <UI.Typography modifiers="primary, thin">
            Schildkrötstraße 15
          </UI.Typography>
          <UI.Typography modifiers="primary, thin">
            68199 Mannheim
          </UI.Typography>
          <UI.Typography modifiers="primary, thin">
            USt-IdNr. DE258032509
          </UI.Typography>
          <UI.Typography modifiers="primary">
            Registergericht Mannheim HRB 704123
          </UI.Typography>
        </UI.Container>
        <UI.Container direction="column" responsive="upToSm.m=0,0,2,0">
          <UI.Typography modifiers="primary">
            Vorstand: Frank Schwarz
          </UI.Typography>
          <UI.Typography modifiers="primary">
            Aufsichtsrat (Vorsitz): Prof. Dr. Stefan Jugel
          </UI.Typography>
          <UI.Typography modifiers="primary">
            Aufsichtsräte: Prof. Dr. Gerald Graf, Prof. Dr. Dietmar von
            Hoyningen-Huen
          </UI.Typography>
        </UI.Container>
        <UI.Container direction="column">
          <UI.Typography modifiers="primary">
            E-Mail: info@markenmehrwert.com
          </UI.Typography>
          <UI.Link onClick={() => navigate(gtcLink)}>
            <UI.Typography
              modifiers="primary, pointer"
              i18n={FOOTER_LINKS_I18N.AGB}
            />
          </UI.Link>
          <UI.Link onClick={() => navigate(imprintLink)}>
            <UI.Typography
              modifiers="primary, pointer"
              i18n={FOOTER_LINKS_I18N.IMPRESSUM}
            />
          </UI.Link>
          <UI.Link onClick={() => navigate(dataProtectionLink)}>
            <UI.Typography
              modifiers="primary, pointer"
              i18n={FOOTER_LINKS_I18N.DATENSCHUTZ}
            />
          </UI.Link>
          <UI.Link onClick={showCookiesModal}>
            <UI.Typography modifiers="primary" i18n={FOOTER_LINKS.COOKIE} />
          </UI.Link>
        </UI.Container>
      </UI.Container>
      <CookieConsent
        visible={cookiesVisible}
        onAccept={hideCookiesModal}
        onDecline={() => window.location.assign('https://www.mmw.ag')}
      />
      <AppVersion />
    </UI.Container>
  );
};

export default Footer;
