import { GetLoginConfigFormData } from '@mmw/redux-store-auth-api-login-config-by-user/hooks/useGetLoginConfigSubmitHandler';
import { useIsLoading } from '@mmw/redux-store-auth-api-recover-password/hooks';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import UI from '@ui-system/ui';
import { F } from 'ts-toolbelt';

interface RecoverSuccessProps {
  askAgain: F.Function<[GetLoginConfigFormData]>;
}

const RecoverPasswordRequestSuccessContent: React.FC<RecoverSuccessProps> = ({
  askAgain,
}: RecoverSuccessProps) => {
  const loading = useIsLoading();
  if (loading) {
    return (
      <UI.Container
        direction="column"
        w="100%"
        align="center"
        gap={3}
        bg="white"
        p="6, 8"
      >
        <UI.Spinner visible size="large" />
      </UI.Container>
    );
  }
  return (
    <UI.Container
      direction="column"
      w="100%"
      align="center"
      gap={3}
      bg="white"
      p="6, 8"
    >
      <UI.Container direction="column" align="center" justify="center" gap={1}>
        <UI.Icon name="check" size={40} color="success" />
        <UI.H5
          i18n={AUTH_I18N.AUTHENTICATION.RECOVER_PASS_EMAIL_SUCCESS}
          modifiers="center"
        />
      </UI.Container>
      <UI.Container direction="column" gap={2}>
        <UI.Link
          i18n={AUTH_I18N.AUTHENTICATION.DIDINT_RECEIVE}
          onClick={askAgain}
          modifiers="underline"
        />
      </UI.Container>
    </UI.Container>
  );
};

export default RecoverPasswordRequestSuccessContent;
