import { I18nShape } from '@mmw/constants-i18n';
import { AUTHENTICATION as I18N } from '@retail/constants-i18n';
import { ContainerProps } from '@ui-system/interfaces-container';
import React from 'react';
import { F, U } from 'ts-toolbelt';

import Action from './Action';

interface Props extends ContainerProps {
  i18n?: I18nShape;
  recoverPasswordUrl?: U.Nullable<string>;
  textModifiers?: string;
  style?: Record<string, any>;
  containerStyle?: Record<string, any>;
  onClick: F.Function<[...any]>;
}

const BackToLoginAction: React.FC<Props> = ({
  useLink,
  linkPrefix,
  linkSuffix,
  textModifiers,
  style,
  containerStyle,
  onClick,
  i18n = I18N.AUTHENTICATION.BACK_TO_LOGIN,
  ...props
}: Props) => (
  <Action
    style={style}
    containerStyle={containerStyle}
    i18n={i18n}
    onClick={onClick}
    useLink={useLink}
    linkPrefix={linkPrefix}
    linkSuffix={linkSuffix}
    textModifiers={textModifiers}
    {...props}
  />
);

export default BackToLoginAction;
