import { css, styled } from '@ui-system/css';
import { ContainerProps, Style } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import { useCurrentHeaderHeight } from '@ui-system/styles';
import UI from '@ui-system/ui';
import { U } from '@utils/ts';
import React, { useMemo } from 'react';

// const innerDefaultStyle = css`
//   width: 100%;
//   height: 100%;
// `;
// max-width: ${({ collapsedArea }) => (collapsedArea ? '100%' : '1440px')};
const InnerContainer = styled(UI.Container)`
  width: 100%;
  height: 100%;
  padding-left: ${({ collapsedArea }) =>
    collapsedArea === false ? '300px' : '20px'};
  padding-right: 20px;
  @media only screen and (min-width: 1750px) {
    max-width: 1440px;
    padding: 20px;
  }
`;

interface InnerProps {
  innerStyle?: Style | Style[];
  collapsedArea?: boolean;
}

export const Inner: React.FC<
  React.PropsWithChildren<InnerProps & ContainerProps>
> = ({
  children,
  innerStyle,
  collapsedArea,
  ...props
}: React.PropsWithChildren<InnerProps & ContainerProps>) => (
  <InnerContainer
    direction="column"
    style={innerStyle}
    collapsedArea={collapsedArea}
    {...props}
  >
    {children}
  </InnerContainer>
);

interface StaticContent {
  Inner: typeof Inner;
}

type ContentStyleProps = {
  headerHeight: U.Nullable<number>;
  collapsedArea?: boolean;
};

const useContentDefaultStyle = makeStyle<ContentStyleProps>(
  ({ props, utils, theme }) => css`
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    min-height: calc(100vh - ${props?.headerHeight}px);
    overflow-x: hidden;
    ${props?.collapsedArea
      ? `
      justify-content: center;
    `
      : `
      justify-content: flex-end;
    `}
  `,
);

interface ContentProps {
  contentStyle?: Style | Style[];
  collapsedArea?: boolean;
}

const Content: React.FC<React.PropsWithChildren<ContentProps>> &
  StaticContent = ({
  children,
  contentStyle,
  collapsedArea,
}: React.PropsWithChildren<ContentProps>) => {
  const headerHeight = useCurrentHeaderHeight();
  const contentDefaultStyle = useContentDefaultStyle({
    headerHeight,
    collapsedArea,
  });
  const containerStyle = useMemo(
    () => ({
      ...contentDefaultStyle,
      ...contentStyle,
    }),
    [contentDefaultStyle, contentStyle],
  );
  return <UI.Container style={containerStyle}>{children}</UI.Container>;
};

Content.Inner = Inner;

export default Content;
