const namespace = 'consumerSearch';
const advancedSearchNamespace = `${namespace}.advancedSearch`;

const consumerMaxDistanceNamespace = 'consumerMaxDistance';

const SEARCH_BY_LAST_NAME = {
  key: `${namespace}.searchByLastName`,
  message: 'Search by Last Name',
};

const ADVANCED_SEARCH = {
  TITLE: {
    key: `${advancedSearchNamespace}.modalTitle`,
    message: 'Consumer search',
  },
  DESCRIPTION: {
    key: `${advancedSearchNamespace}.modalDescription`,
    message: 'Here you can search for already registered consumers.',
  },
  SEARCH_RESULT: {
    key: `${advancedSearchNamespace}.searchResult`,
    message: 'Search result',
  },
  CONSUMERS_NOT_FOUND: {
    key: `${advancedSearchNamespace}.notFound`,
    message: 'No consumers found',
  },
};

const CONSUMER_DISTANCE_CAMPAIGN_SWITCH = {
  TITLE: {
    key: `${consumerMaxDistanceNamespace}Title`,
    message: 'Please confirm that this was indeed a sale',
  },
  CONTENT: {
    key: `${consumerMaxDistanceNamespace}Content`,
    message:
      "You have made a sale in a stationary store, but the end customer's zip code differs significantly from that of your store. Please confirm that this was actually a stationary sale. Alternatively, you can also register online. In this case, please note that the online consultation must be equivalent to a stationary consultation, i.e. a consultation by telephone does not meet these quality standards!",
  },
  DENY_ACTION: {
    key: `${consumerMaxDistanceNamespace}.denyAction`,
    message: 'No, redirect me to the online campaign',
  },
};

const I18N = {
  ADVANCED_SEARCH,
  SEARCH_BY_LAST_NAME,
  CONSUMER_DISTANCE_CAMPAIGN_SWITCH,
};

export default I18N;
