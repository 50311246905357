import {
  HeaderProps,
  PAGE_FOOTER_UUID,
  PAGE_HEADER_UUID,
} from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const usePageHeaderDefaultStyle = makeStyle<Partial<HeaderProps>>(
  ({ css, theme }) => css`
    position: fixed;
    top: 0;
    z-index: 2;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    background-color: ${theme.colors[theme.mode].white};
  `,
);

const usePageFooterDefaultStyle = makeStyle<Partial<HeaderProps>>(
  ({ css, theme }) => css`
    margin-top: auto;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    background-color: ${theme.colors[theme.mode].white};
  `,
);

export const useScreenHeaderStyle = makeFinalStyleHook(
  PAGE_HEADER_UUID,
  usePageHeaderDefaultStyle,
);

export const useScreenFooterStyle = makeFinalStyleHook(
  PAGE_FOOTER_UUID,
  usePageFooterDefaultStyle,
);
