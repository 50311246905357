import borderSchemas from '@mmw/ui-theme/modifiers/color/border';
import { StyledProps } from '@mmw/ui-theme/types';
import iconModifiers from '@mmw/ui-web-elements-icon/theme/modifiers';
import buttonStyle from '@mmw/ui-web-materials-button/theme/style';
import textStyle from '@mmw/ui-web-materials-button/theme/textStyle';

const modifiers = borderSchemas;

export default {
  style: ({ theme, disabled }: StyledProps<{ disabled?: boolean }>) => `
    ${buttonStyle({ theme, disabled })};
    min-height: 40px;
    transition: 0.3s;
    &:focus-within {
      outline: none;
      transition: 0.3s;
      box-shadow: 0 0 4px ${theme?.colors.pallet.secondary};
    }
    ${
      disabled &&
      `
      background-color: ${theme?.colors.pallet.basic};
      cursor: default;
    `
    };
    ${theme?.media.desktop(`
      &:hover {
        background-color: ${theme?.colors.pallet.secondary};
        ${
          disabled &&
          `
          background-color: ${theme?.colors.pallet.basic};
        `
        };
      }
    `)}
    ${
      !disabled &&
      `
      ::-moz-focus-inner {
      border: 0;
    }
    transform: translate3d(0, 0, 0);
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10,10);
      opacity: 0;
      transition: transform .5s, opacity 1s;
    }
    &:active:after {
      transform: scale(0,0);
      opacity: .2;
      transition: 0s;
    }
    `
    }
  `,
  icon: {
    style: ({ theme }: StyledProps<unknown>) => `
      fill: ${theme?.colors.pallet.light};
      padding: 0;
      margin: 0 10px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    `,
    modifiers: iconModifiers,
  },
  text: {
    style: ({ theme }: StyledProps<unknown>) => `
      ${textStyle({ theme })}
      color: ${theme?.colors.pallet.light};
    `,
    modifiers,
  },
  modifiers,
  variable: {
    BORDER_RADIUS: 0,
  },
};
