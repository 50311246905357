import { ValidateEmailByTAN } from '@auth/view-two-factor-auth';
import React from 'react';

import InformEmail from '../elements/InformEmail';
import NewPassword from '../elements/NewPassword';
import { ContentProps, PasswordRedefinitionSteps } from '../types';

const Content: React.FC<ContentProps> = ({
  step,
  onInformUserMailSuccess,
  onTanCodeVerifySuccess,
  onResendCodeRequest,
  onSubmitNewPassword,
  // onCancelOperation,
  operationInProcess,
}: ContentProps) => (
  <>
    {
      {
        [PasswordRedefinitionSteps.INFORM_EMAIL]: (
          <InformEmail
            onInformUserMailSuccess={onInformUserMailSuccess}
            loading={operationInProcess}
          />
        ),
        [PasswordRedefinitionSteps.TAN_CODE_VALIDATION]: (
          <ValidateEmailByTAN
            onVerificationSuccess={onTanCodeVerifySuccess}
            onResendRequest={onResendCodeRequest}
            operationInProcess={operationInProcess}
          />
          // <ValidateToken
          //   onVerificationSuccess={onTanCodeVerifySuccess}
          //   onResendRequest={onResendCodeRequest}
          //   onCancel={onCancelOperation}
          //   operationInProcess={operationInProcess}
          // />
        ),
        [PasswordRedefinitionSteps.NEW_PASSWORD]: (
          <NewPassword
            onSubmitSuccess={onSubmitNewPassword}
            // onCancel={onCancelOperation}
          />
        ),
      }[step as PasswordRedefinitionSteps]
    }
  </>
);

export default Content;
