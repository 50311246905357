import {
  PasswordField,
  PasswordForgottenAction,
  UserField,
} from '@auth/view-form-components';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { SIGN_IN as SIGN_IN_I18N } from '@mmw/constants-i18n-authentication';
import { CREATE_NOW, SIGNIN } from '@mmw/constants-i18n-buttons';
import { useIsLoading } from '@mmw/redux-store-auth-api-login-by-password/hooks';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

import Form from './Form';

export interface LoginContentProps {
  onSuccess?: F.Function;
  customErrorConfigs?: Record<string, React.FC>;
  onClickRecoverPass?: F.Function;
  onClickNewAccount?: F.Function;
  showRecover?: boolean;
  showNewAccount?: boolean;
}

const Login: React.FC<LoginContentProps> = ({
  onSuccess,
  customErrorConfigs,
  onClickRecoverPass,
  onClickNewAccount,
  showRecover = true,
  showNewAccount = true,
}: LoginContentProps) => {
  const isLoading = useIsLoading();
  return (
    <Form onSuccess={onSuccess} customErrorConfigs={customErrorConfigs}>
      <UserField label={AUTH_I18N.AUTHENTICATION.INFORM_USER} />
      <PasswordField label={AUTH_I18N.AUTHENTICATION.INFORM_YOUR_PASSWORD} />
      <UI.Container
        justify="space-between"
        m={showNewAccount ? '0, 0, 8, 0' : ''}
      >
        <FastFormSubmitButton i18n={SIGNIN} disabled={isLoading} />
        {showRecover ? (
          <UI.Container justify="flex-end" p="1, 0">
            <PasswordForgottenAction
              onClick={onClickRecoverPass}
              linkSuffix="?"
              textModifiers="capitalize, grayA300"
              textVariant="body1"
              useLink
            />
          </UI.Container>
        ) : null}
      </UI.Container>
      {showNewAccount ? (
        <UI.Container align="center" justify="flex-end" gap={2}>
          <UI.Typography
            i18n={SIGN_IN_I18N.DONT_HAVE_MMW_ID}
            modifiers="capitalize, grayA300"
          />
          <UI.Button i18n={CREATE_NOW} onClick={onClickNewAccount} />
        </UI.Container>
      ) : null}
    </Form>
  );
};

export default Login;
