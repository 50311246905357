// import Login, { LoginContentProps } from '@auth/view-login';

import { AuthenticationResponse } from '@mmw/services-auth-api-authentication/types';
import { Login } from '@mmw/shared-pages';
import { F } from '@utils/ts';
import axios from 'axios';
import React, { useCallback } from 'react';

// interface LoginPageProps extends LoginContentProps {
//   contentStyle?: Style | Style[];
// }

// const style = css`
//   justify-content: center;
// `;

function isDomainDifferent(url: string): boolean {
  try {
    // // eslint-disable-next-line no-debugger
    // debugger;
    // @ts-ignore
    const urlA = new URL(window.location);
    const urlB = new URL(url);
    return urlA.origin !== urlB.origin;
  } catch (e) {
    return false;
  }
}

export function useOnLoginSuccessHandler(): F.Function<
  [AuthenticationResponse],
  void
> {
  return useCallback((data: AuthenticationResponse) => {
    const { redirectUrl, onLoginUrl } = data;
    function redirect() {
      if (redirectUrl) window.location.replace(redirectUrl);
    }

    function redirectWhenPossibleCorsError() {
      const { accessToken, refreshToken } = data;
      const redirectOnLogin = onLoginUrl;

      const url = new URL(redirectOnLogin as string);
      url.searchParams.append('accessToken', accessToken as string);
      url.searchParams.set('refreshToken', refreshToken as string);
      url.searchParams.set('redirectUrl', redirectUrl as string);
      window.location.replace(url.toString());
    }

    if (!onLoginUrl) {
      redirect();
      return;
    }
    if (isDomainDifferent(redirectUrl as string)) {
      redirectWhenPossibleCorsError();
      return;
    }
    axios
      .post(onLoginUrl, data, { withCredentials: true })
      .then(() => {
        redirect();
      })
      .catch(() => {
        redirectWhenPossibleCorsError();
      });
  }, []);
}

// const LoginPage: React.FC<LoginPageProps> = ({
//   contentStyle = style,
//   ...props
// }: LoginPageProps) => {
//   const onSuccess = useOnLoginSuccessHandler();
//   const onClickRecover = useNavigateToRecoverPassword();
//   const onClickNewAccount = useNavigateToNewAccount();
//   const assets = useAssets();
//   return (
//     <Page
//       backgroundImageUrl={assets?.loginBackgroundImage}
//       backgroundSize="cover"
//     >
//       <Page.Content contentStyle={contentStyle}>
//         <ReCaptchaV3ContextProvider>
//           <UI.Container justify="center">
//             <ResourcesContextProvider prefixKey={getApplicationId()}>
//               <Login
//                 onSuccess={onSuccess}
//                 onClickRecoverPass={onClickRecover}
//                 onClickNewAccount={onClickNewAccount}
//                 showNewAccount={false}
//                 {...props}
//               />
//             </ResourcesContextProvider>
//           </UI.Container>
//         </ReCaptchaV3ContextProvider>
//       </Page.Content>
//     </Page>
//   );
// };

const LoginPageWrapper: React.FC = () => {
  const onLoginSuccess = useOnLoginSuccessHandler();
  return (
    <Login
      onLoginSuccess={onLoginSuccess}
      // onClickRecoverPass={() =>
      //   window.open(getRetailClientRecoverPasswordUrl(), '_blank')
      // }
      showNewAccount={false}
    />
  );
};

export default LoginPageWrapper;
