import NewPassword from '@auth/view-password-redefinition/elements/NewPassword';
import { useNavigateTo } from '@lib/navigation';
import {
  useChangePassword,
  useError,
  useIsValidUuid,
  useValidateChangePasswordUuid,
} from '@mmw/redux-store-auth-api-recover-password/hooks';
import { BAD_CREDENTIALS } from '@mmw/redux-store-payload-error/constants';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import { useNavigateToInformEmail } from '@retail/login-or-signup-ui-web/hooks';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import delay from 'lodash/delay';
import React, { useCallback, useEffect, useState } from 'react';
import { useToastNotificationFunction } from 'toast-notifications/hooks/useToastNotification';

interface Props {
  onSuccessRoute: string;
}

const NewPasswordFormByUUID: React.FC<Props> = ({ onSuccessRoute }: Props) => {
  const [validated, setValidated] = useState(false);
  const navigateToEnterEmail = useNavigateToInformEmail();
  const validate = useValidateChangePasswordUuid();
  const changePassword = useChangePassword();
  const isValid = useIsValidUuid();
  // const notify = useNotifications();
  const error = useError();
  const isMobile = useIsMediaQueryUpToMD();
  const navigateTo = useNavigateTo();
  const navigateCallback = useCallback(() => {
    if (onSuccessRoute) {
      navigateTo(onSuccessRoute);
    } else navigateToEnterEmail();
  }, [navigateTo, navigateToEnterEmail, onSuccessRoute]);
  const notification = useToastNotificationFunction({
    anchorOrigin: {
      horizontal: 'center',
      vertical: isMobile ? 'top' : 'bottom',
    },
    autoHideDuration: 5000,
    onClose: navigateCallback,
  });
  const handleSubmit = useCallback(
    ({ password }) => {
      if (validated && isValid) {
        changePassword(password, () =>
          notification(AUTH_I18N.AUTHENTICATION.PASSWORD_CHANGED, 'success'),
        );
        delay(() => {
          navigateCallback();
        }, 7000);
      }
    },
    [changePassword, isValid, navigateCallback, notification, validated],
  );
  useEffect(() => {
    if (error || (validated && !isValid)) {
      notification(BAD_CREDENTIALS.I18N.DESCRIPTION, 'error');
      setValidated(false);
    }
  }, [error, isValid]);

  useEffect(() => {
    validate();
    setValidated(true);
  }, [validate]);

  return <NewPassword onSubmitSuccess={handleSubmit} />;
};

export default NewPasswordFormByUUID;
