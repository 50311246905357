// import { NewAccount } from '@mmw/shared-features';
import NewAccount from '@auth/view-account-creation';
import BackToLoginAction from '@auth/view-form-components/actions/BackToLogin';
import AuthCard from '@auth/view-form-components/AuthCard';
import { LoginContentProps } from '@manufacturer/login-ui';
import { useNavigateToLogin } from '@mmw/common-navigation/hooks';
import { I18nShape } from '@mmw/constants-i18n';
import { SIGN_IN, SIGN_UP } from '@mmw/constants-i18n-authentication';
import Page from '@mmw/ui-page';
import { ReCaptchaV3ContextProvider } from '@recaptcha/v3';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';
import { ObjectSchema } from 'yup';

interface LoginPageProps extends LoginContentProps {
  contentStyle?: Style | Style[];
  onSuccess: F.Function;
  customTitle?: I18nShape;
  customDescription?: I18nShape;
  customValidationSchema?: ObjectSchema<any>;
}

const style = css`
  justify-content: center;
`;

const NewAccountPage: React.FC<LoginPageProps> = ({
  contentStyle = style,
  onSuccess,
  customTitle,
  customDescription,
  customValidationSchema,
  ...props
}: LoginPageProps) => {
  const onCancel = useNavigateToLogin();
  const assets = useAssets();
  return (
    <Page
      backgroundImageUrl={assets?.loginBackgroundImage}
      backgroundSize="cover"
    >
      <Page.Content contentStyle={contentStyle}>
        <ReCaptchaV3ContextProvider>
          <UI.Container justify="center" w={500} p="4, 0">
            <AuthCard
              titleI18n={customTitle || SIGN_IN.NEW_MMW_ID}
              descI18n={customDescription || SIGN_UP.INTRODUCTION}
              backActionContent={
                <BackToLoginAction
                  style={css`
                    text-align: right;
                    text-wrap: nowrap;
                  `}
                  onClick={onCancel}
                  textModifiers="capitalize, grayA300"
                  textVariant="caption"
                  useLink
                />
              }
            >
              <NewAccount
                onSuccess={onSuccess}
                customValidationSchema={customValidationSchema}
                {...props}
              />
            </AuthCard>
          </UI.Container>
        </ReCaptchaV3ContextProvider>
      </Page.Content>
    </Page>
  );
};

export default NewAccountPage;
