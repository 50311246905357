import { createDI } from '@di/core';
import { Style } from '@ui-system/interfaces';
import { ContainerProps } from '@ui-system/interfaces-container';
import { makeStyle, useMergedStyles } from '@ui-system/style';
import UI from '@ui-system/ui';
import React from 'react';

import { MobileNavItem, NavItem, NavItemType } from './NavItem';
import { NavItemConfigInterface } from './types';

type NavMenuProps = ContainerProps;

const useStyle = makeStyle(
  ({ utils, theme, css }) => css`
    position: fixed;
    z-index: 999;
    ${utils.applyShadow(theme, 2)}
  `,
);

interface Statics {
  NavItem: NavItemType;
  MobileNavItem: NavItemType;
}

type NavMenuType = React.FC<NavMenuProps> & Statics;

export const [getNavMenuRoutes, setNavMenuRoutes] =
  createDI<NavItemConfigInterface[]>('NAV_MENU_ROUTES');

export const [getHeaderAuthenticatedContent, setHeaderAuthenticatedContent] =
  createDI<React.FC | null>('HEADER_AUTHENTICATED_CONTENT');

export const NavMenu: NavMenuType = ({
  children,
  style,
  ...rest
}: NavMenuProps) => {
  const headerStyle = useStyle();
  const finalStyle = useMergedStyles(headerStyle, style as Style);
  return (
    <UI.Page.Header style={finalStyle}>
      <UI.Container w="100%" {...rest}>
        {children}
      </UI.Container>
    </UI.Page.Header>
  );
};

NavMenu.NavItem = NavItem;
NavMenu.MobileNavItem = MobileNavItem;
