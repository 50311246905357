import qs from 'qs';

import { GetClassOfGoodsQueryString } from './types';

const BASE_PATH = '/v1/manu/product';

export const GetManuProductsPath = (language: string): string =>
  `${BASE_PATH}/search?${qs.stringify({
    language,
  })}`;

export const GetManuProductsCampaignsPath = (language: string): string =>
  `${BASE_PATH}/products-campaigns?${qs.stringify({
    language,
  })}`;

export const GetManuProductsClassOfGoods = (
  language: string,
  request?: GetClassOfGoodsQueryString,
): string =>
  `${BASE_PATH}/classofgoods-hierarchy?${qs.stringify({
    ...(request || {}),
    language,
  })}`;
