import { createDI } from '@di/core';
import defaultApiV2 from '@mmw/api-v2';
import logger from '@mmw/logging-logger';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import { AxiosResponse } from 'axios';
import { map, nth } from 'lodash';
import compact from 'lodash/compact';

import {
  GetManuProductsCampaignsPath,
  GetManuProductsClassOfGoods,
  GetManuProductsPath,
} from './apiPaths';
import {
  CampaignJSON,
  ClassOfGoodTreeNode,
  GetClassOfGoodsQueryString,
  ManuProductRequestJSON,
  ManuProductResponseJSON,
  ProductSearchJSON,
} from './types';

type Api = typeof defaultApiV2;

type ManuProductsServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class ManuProductsService {
  api: Api;

  authenticationService: AuthenticationService;

  static createDI = createDI<ManuProductsService>;

  constructor({ apiv2, authenticationService }: ManuProductsServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async search(
    request: ManuProductRequestJSON,
    language: string,
  ): Promise<ManuProductResponseJSON> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();

      const response: AxiosResponse<ManuProductResponseJSON> =
        await this.api.post(GetManuProductsPath(language), request, {
          headers,
          params: { language },
        });

      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async searchProductsWithCampaigns(
    request: ManuProductRequestJSON,
    language: string,
  ): Promise<ManuProductResponseJSON> {
    try {
      const productsResponse = await this.search(request, language);

      const productIDs = compact(map(productsResponse.list, 'productID'));

      const campaigns = await this.productsCampaigns({ productIDs }, language);

      productsResponse.list = map(productsResponse.list, (product, index) => ({
        ...product,
        productCampaigns: nth(campaigns, index),
      }));
      return productsResponse;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async productsCampaigns(
    request: ProductSearchJSON,
    language: string,
  ): Promise<CampaignJSON[][]> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<CampaignJSON[][]> = await this.api.post(
        GetManuProductsCampaignsPath(language),
        request,
        { headers },
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async searchProductsClassOfGoods(
    language: string,
    request?: GetClassOfGoodsQueryString,
  ): Promise<ClassOfGoodTreeNode[]> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<ClassOfGoodTreeNode[]> = await this.api.get(
        GetManuProductsClassOfGoods(language, request),
        { headers },
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async searchProductsGood(
    request: ManuProductRequestJSON,
    language: string,
  ): Promise<any> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<ManuProductResponseJSON> =
        await this.api.post(GetManuProductsPath(language), request, {
          headers,
        });
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }
}

export default ManuProductsService;
