// eslint-disable-next-line camelcase
const REGEX_CONTAINS_EMAIl = /\S+@\S+\.\S+/;
const REGEX_EXACT_IS_EMAIL = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function stringContainsEmail(text: string) {
  // eslint-disable-next-line camelcase
  return REGEX_CONTAINS_EMAIl.test(text);
}

// eslint-disable-next-line import/prefer-default-export
export function isEmail(email: string) {
  return REGEX_EXACT_IS_EMAIL.test(email);
}
