import { generateColorKit, generateLightenColor } from '@ui-system/color';
import { createTheme, extendTheme, ThemeMode } from '@ui-system/theme';
import { ColorMode } from '@ui-system/theme/colors';

import assets from './assets';
import { TYPOGRAPHY } from './typography';

export const MMW_COLORS: Partial<ColorMode> = {
  primary: generateColorKit('#004A96'),
  secondary: generateColorKit('#4A8BC2'),
  info: '#AAAAAA',
  background: '#F7F7F7',
  basicLight: '#EEEEEE',
  error: '#B70000',
  gray: {
    A900: '#3C3C3C',
    A800: generateLightenColor('#3C3C3C', 0.1),
    A700: generateLightenColor('#3C3C3C', 0.2),
    A600: '#4D4D4D',
    A500: generateLightenColor('#4D4D4D', 0.1),
    A400: generateLightenColor('#4D4D4D', 0.2),
    A300: '#7C7C7C',
    A200: generateLightenColor('#7C7C7C', 0.1),
    A100: generateLightenColor('#7C7C7C', 0.2),
  },
};

export const MMW_THEME = createTheme({
  colors: {
    [ThemeMode.LIGHT]: MMW_COLORS,
  },
  typography: TYPOGRAPHY,
});

export const MMW_THEME_WEB = extendTheme(MMW_THEME, {
  colors: {
    [ThemeMode.LIGHT]: MMW_COLORS,
  },
  typography: TYPOGRAPHY,
  assets,
});

export * from './configureComponents';
export * from './StylesProvider';
