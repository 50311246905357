import { BackToLoginAction } from '@auth/view-form-components';
import AuthCard from '@auth/view-form-components/AuthCard';
import RedefinePasswordContent, {
  PasswordRedefinitionSteps,
  usePasswordChangeStepControl,
} from '@auth/view-password-redefinition';
import { useSubmitPasswordSuccess } from '@consumer/hooks';
import { useNavigateToAuthentication } from '@consumer/navigation';
import { usePasswordChangeSuccessStatus } from '@consumer/profile-redux-store/hooks';
import { LoginContentProps } from '@manufacturer/login-ui';
import { useNavigateToLogin } from '@mmw/common-navigation/hooks';
import { getApplicationId } from '@mmw/contextual-config';
import { OperationsEnum } from '@mmw/services-auth-api-authentication/types';
import Page from '@mmw/ui-page';
import { ResourcesContextProvider } from '@mmw/ui-providers-resources';
import { ReCaptchaV3ContextProvider } from '@recaptcha/v3';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import React, { useCallback } from 'react';

const TITLES_AND_DESCRIPTIONS = {
  [PasswordRedefinitionSteps.INFORM_EMAIL]: {
    TITLE: AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD,
    DESCRIPTION: AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD_DESCRIPTION,
  },
  [PasswordRedefinitionSteps.TAN_CODE_VALIDATION]: {
    TITLE: AUTH_I18N.AUTHENTICATION.SECURITY_TOKEN,
    DESCRIPTION: AUTH_I18N.AUTHENTICATION.PLEASE_ENTER_THE_SECURITY_TOKEN,
  },
  [PasswordRedefinitionSteps.NEW_PASSWORD]: {
    TITLE: AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD,
    DESCRIPTION: null,
  },
};

interface LoginPageProps extends LoginContentProps {
  contentStyle?: Style | Style[];
}

const style = css`
  justify-content: center;
`;

const RecoverPasswordPage: React.FC<LoginPageProps> = ({
  contentStyle = style,
  ...props
}: LoginPageProps) => {
  const goBack = useNavigateToLogin();
  const navigateToAuth = useNavigateToAuthentication();
  const onSubmitNewPassword = useSubmitPasswordSuccess();
  const passwordChangedWithSuccess = usePasswordChangeSuccessStatus();
  const assets = useAssets();
  const { currentStep, setNextStep } = usePasswordChangeStepControl(
    PasswordRedefinitionSteps.INFORM_EMAIL,
  );
  const onCancelOperation = useCallback(() => {
    setNextStep(null);
    goBack();
  }, [goBack, setNextStep]);
  const currentStepContent =
    TITLES_AND_DESCRIPTIONS[currentStep as PasswordRedefinitionSteps];

  return (
    <Page
      backgroundImageUrl={assets?.loginBackgroundImage}
      backgroundSize="cover"
    >
      <Page.Content contentStyle={contentStyle}>
        <ReCaptchaV3ContextProvider>
          <UI.Container justify="center">
            <ResourcesContextProvider prefixKey={getApplicationId()}>
              <AuthCard
                titleI18n={currentStepContent.TITLE}
                descI18n={currentStepContent.DESCRIPTION}
                backActionContent={
                  <BackToLoginAction
                    style={css`
                      text-align: right;
                      text-wrap: nowrap;
                    `}
                    onClick={goBack}
                    textModifiers="capitalize, grayA300"
                    textVariant="caption"
                    useLink
                  />
                }
              >
                <RedefinePasswordContent
                  currentStep={currentStep}
                  setNextStep={setNextStep}
                  onSubmitNewPassword={onSubmitNewPassword}
                  onCancel={onCancelOperation}
                  onSuccess={navigateToAuth}
                  operation={OperationsEnum.USER_CHANGE_PASSWORD}
                  successStatus={passwordChangedWithSuccess}
                  {...props}
                />
              </AuthCard>
            </ResourcesContextProvider>
          </UI.Container>
        </ReCaptchaV3ContextProvider>
      </Page.Content>
    </Page>
  );
};

export default RecoverPasswordPage;
