import {
  NEW_PASSWORD_VALIDATION_SCHEMA,
  NewPasswordForm,
} from '@auth/form-data';
import { PasswordField } from '@auth/view-form-components';
import { PasswordConfirmationField } from '@auth/view-form-components/PasswordField';
import { AUTHENTICATION_I18N } from '@consumer/constants-i18n';
import { useIsLoading } from '@consumer/profile-redux-store/hooks';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { CANCEL, CONFIRM } from '@mmw/constants-i18n-buttons';
import { PASSWORD } from '@mmw/constants-i18n-common';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

const INITIAL_VALUES = new NewPasswordForm();

interface Props {
  onSubmitSuccess: F.Function<[NewPasswordForm]>;
  onCancel?: F.Function;
}

const NewPassword: React.FC<Props> = ({ onSubmitSuccess, onCancel }: Props) => {
  const loading = useIsLoading();
  return (
    <FastFormProvider
      // @ts-ignore
      validationSchema={NEW_PASSWORD_VALIDATION_SCHEMA}
      initialValues={INITIAL_VALUES}
      onSubmitSuccess={onSubmitSuccess}
    >
      <UI.Container direction="column" w="100%" gap={4}>
        <PasswordField
          label={PASSWORD}
          accessoryLeft={
            <UI.Icon name="password" size={20} color="primary.light" />
          }
        />
        <PasswordConfirmationField
          label={AUTHENTICATION_I18N.SIGN_UP.CONFIRM_PASSWORD}
          accessoryLeft={
            <UI.Icon name="password" size={20} color="primary.light" />
          }
        />
        <UI.Container
          w="100%"
          justify={onCancel ? 'space-between' : 'flex-start'}
        >
          {onCancel ? (
            <UI.Button i18n={CANCEL} onClick={onCancel} modifiers="secondary" />
          ) : null}
          <FastFormSubmitButton
            style={css`
              max-width: 180px;
            `}
            i18n={CONFIRM}
            loading={loading}
          />
        </UI.Container>
      </UI.Container>
    </FastFormProvider>
  );
};

export default NewPassword;
