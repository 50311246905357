// import SuccessContent from './SendMailSuccessContent';
import { BaseUsernameForm, USERNAME_VALIDATION_SCHEMA } from '@auth/form-data';
import { UserField } from '@auth/view-form-components';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { RECOVER } from '@mmw/constants-i18n-buttons';
import useGetLoginConfigSubmitHandler from '@mmw/redux-store-auth-api-login-config-by-user/hooks/useGetLoginConfigSubmitHandler';
import {
  useEmailSentStatus,
  useError as useSendEmailError,
  useIsLoading,
  useRecoverPassword,
} from '@mmw/redux-store-auth-api-recover-password/hooks';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useToastNotificationFunction } from 'toast-notifications/hooks/useToastNotification';

import RecoverPasswordRequestSuccessContent from './SendMailSuccessContent';

const INITIAL_VALUES = new BaseUsernameForm();

const InformUserId: React.FC = () => {
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [requestData, setRequestData] = useState();
  const recoverPassword = useRecoverPassword();
  const isLoading = useIsLoading();
  const emailSentStatus = useEmailSentStatus();
  const error = useSendEmailError();
  // const notify = useNotifications();
  const isMobile = useIsMediaQueryUpToMD();
  const notification = useToastNotificationFunction({
    anchorOrigin: {
      horizontal: 'center',
      vertical: isMobile ? 'top' : 'bottom',
    },
    onClose: () => setShowSuccessNotification(false),
    autoHideDuration: 5000,
  });
  const getLoginConfigSubmitHandler =
    useGetLoginConfigSubmitHandler(recoverPassword);
  const resendCodeRequest = useCallback(
    values => {
      if (values) {
        // recoverPassword(values[FIELD_PATHS.username.$path]);
        // setJustSubmitted(true);
        getLoginConfigSubmitHandler({ emailOrUserid: values?.username });
        setRequestData(values);
      }
    },
    [getLoginConfigSubmitHandler],
  );
  const onSubmitSuccess = useCallback(
    values => {
      if (isEmpty(requestData)) {
        setRequestData(values);
      }
      getLoginConfigSubmitHandler({ emailOrUserid: values?.username });
    },
    [requestData, getLoginConfigSubmitHandler],
  );

  useEffect(() => {
    if (showSuccessNotification) {
      notification(
        AUTH_I18N.AUTHENTICATION.SENT_CHANGE_PASSWORD_EMAIL,
        'success',
      );
      // notification(
      //   AUTH_I18N.AUTHENTICATION.SENT_CHANGE_PASSWORD_EMAIL,
      //   'success',
      // );
    }
  }, [showSuccessNotification]);

  useEffect(() => {
    if (emailSentStatus) {
      setShowSuccessNotification(true);
    }
  }, [requestData, emailSentStatus]);

  useEffect(() => {
    if (error || (!isEmpty(requestData) && emailSentStatus === false)) {
      notification(AUTH_I18N.AUTHENTICATION.RECOVERY_PASSWORD_ERROR, 'error');
    }
  }, [error, emailSentStatus]);

  if (!isEmpty(requestData) && emailSentStatus) {
    return (
      <RecoverPasswordRequestSuccessContent
        askAgain={() => resendCodeRequest(requestData)}
      />
    );
  }

  return (
    <FastFormProvider
      initialValues={INITIAL_VALUES}
      validationSchema={USERNAME_VALIDATION_SCHEMA}
      onSubmitSuccess={onSubmitSuccess}
    >
      <UI.Container direction="column" w="100%" gap={4}>
        <UserField
          label={AUTH_I18N.AUTHENTICATION.INFORM_USER}
          accessoryLeft={
            <UI.Icon name="person" size={20} color="primary.light" />
          }
        />
        <FastFormSubmitButton
          style={css`
            max-width: 180px;
          `}
          i18n={RECOVER}
          disabled={isLoading}
          loading={isLoading}
        />
      </UI.Container>
    </FastFormProvider>
  );
};

export default InformUserId;
