import { useNavigate } from '@mmw/ui-web-libraries-react-router/hooks';
import { ENTER_EMAIL } from '@retail/constants-routes';
import { useCallback } from 'react';

function useNavigateToInformEmail() {
  const navigate = useNavigate();
  return useCallback(() => navigate(ENTER_EMAIL), [navigate]);
}

export default useNavigateToInformEmail;
