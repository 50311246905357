import config from '@mmw/config';
import { RETAIL_CLIENT } from '@mmw/constants-application-ids';
import {
  RETAIL_CLIENT as DEFAULT_RESOURCE_BUNDLE_NAME,
  RETAIL_CLIENT_V4 as SECONDARY_RESOURCE_BUNDLE_NAME,
} from '@mmw/constants-bundle-names';
import { RETAIL_CLIENT as CONTEXT_PATH } from '@mmw/constants-context-paths';
import { EUROPE_COUNTRIES, GERMANY } from '@mmw/constants-country-codes';
import { DE, EN, FR, JA, PL } from '@mmw/constants-languages';
import { NOOP as SALESORGBRAND_ID } from '@mmw/constants-salesorgbrand-ids';
import { ContextualConfig } from '@mmw/contextual-config';
import log from '@mmw/logging-logger';
import detectBrowserLanguage, {
  getUserDeviceCountryByTimeZone,
} from '@mmw/web-utils-detect-browser-language';

const SUPPORTED_LANGUAGES = [DE, EN, FR, JA, PL];
// If you need to use another language, you must add your path in the i18n provider
const APPLICATION_ID = RETAIL_CLIENT;
const logger = log.extend(APPLICATION_ID);
const DEFAULT_LANGUAGE = detectBrowserLanguage(SUPPORTED_LANGUAGES, DE);
const DEFAULT_COUNTRY = getUserDeviceCountryByTimeZone(
  EUROPE_COUNTRIES,
  GERMANY,
);

// TODO: change e-mail resources for TAN request
export const TAN_EMAIL_SUBJECT_RESOURCEKEY =
  'TODO_retailPortalRequestTan.subject';
export const TAN_EMAIL_BODY_RESOURCEKEY = 'TODO_retailPortalRequestTan.content';
export const SMS_RESOURCEKEY = 'TODO_retailPortalRequestTanBySms';
export const WELCOME_EMAIL_BODY_RESOURCEKEY =
  'TODO_retailPortalWelcomeEmail.content';
export const WELCOME_EMAIL_SUBJECT_RESOURCEKEY =
  'TODO_retailPortalWelcomeEmail.subject';
export const COMPLEX_ACCOUNT_EMAIL_BODY_RESOURCEKEY =
  'TODO_retailPortalComplexAccount.content';
export const COMPLEX_ACCOUNT_EMAIL_SUBJECT_RESOURCEKEY =
  'TODO_retailPortalComplexAccount.subject';
export const BUNDLE_NAME = DEFAULT_RESOURCE_BUNDLE_NAME;

const RECOVER_PASSWORD_EMAIL_BODY_RESOURCEKEY =
  'email.passwordRecoverLinkMailContent'; // TODO: CHECK RIGHT RESOURCES
const RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCEKEY =
  'email.eMailSubjectResetPassword'; // TODO: CHECK RIGHT RESOURCES

const RECOVER_USERNAME_EMAIL_BODY_RESOURCEKEY =
  'email.usernameRecoverLinkMailContent'; // TODO: CHECK RIGHT RESOURCES
const RECOVER_USERNAME_EMAIL_SUBJECT_RESOURCEKEY =
  'email.eMailSubjectUsernameRecover'; // TODO: CHECK RIGHT RESOURCES

// @ts-ignore
const contextualConfig: ContextualConfig = {
  ...config,
  application: {
    displayName: 'marken mehwert AG',
    applicationId: APPLICATION_ID,
    salesOrgBrandID: SALESORGBRAND_ID,
    supportedLanguages: SUPPORTED_LANGUAGES,
    supportedCountries: EUROPE_COUNTRIES,
    defaultLanguage: DEFAULT_LANGUAGE,
    defaultCountry: DEFAULT_COUNTRY,
    defaultResourceBundleName: DEFAULT_RESOURCE_BUNDLE_NAME,
    secondaryResourceBundleName: SECONDARY_RESOURCE_BUNDLE_NAME,
    checkAuthenticationTimeout: 15 * 60 * 1000,
    logger,
    resources: {
      recoverPasswordEmailConfiguration: {
        subjectResourcekey: RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCEKEY,
        bodyResourcekey: RECOVER_PASSWORD_EMAIL_BODY_RESOURCEKEY,
      },
      recoverUsernameEmailConfiguration: {
        subjectResourcekey: RECOVER_USERNAME_EMAIL_SUBJECT_RESOURCEKEY,
        bodyResourcekey: RECOVER_USERNAME_EMAIL_BODY_RESOURCEKEY,
      },
      REQUEST_EMAIL_VERIFICATION_EMAIL_BODY_RESOURCEKEY:
        TAN_EMAIL_BODY_RESOURCEKEY,
      REQUEST_EMAIL_VERIFICATION_EMAIL_SUBJECT_RESOURCEKEY:
        TAN_EMAIL_SUBJECT_RESOURCEKEY,
      REQUEST_PHONE_VERIFICATION_BY_SMS_RESOURCEKEY: SMS_RESOURCEKEY,
    },
    version: process.env.REACT_APP_VERSION as string,
  },
  logs: {
    applicationId: APPLICATION_ID,
  },
  googleTagManager: {
    // TODO: change GTM ID
    gtmId: '',
  },
  googleAnalytics: {
    // TODO: change GA ID
    trackingID: '',
  },
  webApplication: {
    contextPath:
      process.env.REACT_APP_NO_CONTEXT_PATH === 'true' ? '' : CONTEXT_PATH,
  },
  clients: {
    retailClient: {
      baseURI: process.env.REACT_APP_APPLICATION_BASE_URL as string,
      version: process.env.REACT_APP_VERSION as string,
    },
  },
};
export default contextualConfig;
