import {
  AEG,
  JURA,
  LG,
  MIELE,
  ORALB_DE,
  REC,
  SalesOrgBrand,
} from '@mmw/constants-salesorgbrand-ids';
import { getSalesOrgBrandId } from '@mmw/contextual-config-utils';
import { useLoggedUserSalesOrgBrandIDs } from '@mmw/redux-store-auth-api-authentication/hooks';
import { some } from 'lodash';
import size from 'lodash/size';

enum Routes {
  HOME = '/',
  LOGIN = '/login',
  AUDIT = '/audit/:id',
  AUDITS = '/audits',
  STORE = '/store/:id',
  STORES = '/stores',
  PROFILE = '/profile',
  TERMS = '/terms',
  ABOUT = '/about',
  IBV = '/ibv/:id',
  IBVS = '/ibvs',
  MARKETING_BUDGETS = '/marketing-budgets',
  MARKETING_BUDGET = '/marketing-budget/:id',
  MASS_MARKETING_BUDGET = '/mass-marketing-budget',
  PROJECTS = '/projects',
  REGISTRATIONS = '/registrations',
  REGISTRATION = '/registration/:id',
  PARTNERS = '/partners',
  PARTNER = '/partner/:id',
  ONLINE_REPORTING = '/online-reporting',
  RECOVER_PASSWORD_ROOT = '/recover-password',
  RECOVER_PASSWORD = '/recover-password/:uuid',
  DISTRIBUTION_CHANNELS = '/distribution-channels',
  USERS = '/users',
  CAMPAIGNS = '/campaigns',
  CAMPAIGN = '/campaign',
  PRODUCTS = '/products',
  NEW_ACCOUNT = '/new-account',
  REC_COINS_STATEMENT = '/recoins/:id',
}

export const HOME_ROUTE = {
  [JURA]: Routes.STORES,
  [ORALB_DE]: Routes.AUDITS,
  [REC]: Routes.MARKETING_BUDGETS,
};

export function getHomeRouteBySob(): Routes {
  return HOME_ROUTE[getSalesOrgBrandId()];
}

export const AVAILABLE_ROUTES_BY_SOB = {
  [JURA]: [
    Routes.AUDITS,
    Routes.AUDIT,
    // Routes.HOME,
    Routes.STORE,
    Routes.STORES,
    Routes.IBV,
    Routes.IBVS,
    Routes.ONLINE_REPORTING,
    Routes.CAMPAIGN,
    Routes.CAMPAIGNS,
  ],
  [ORALB_DE]: [Routes.AUDIT, Routes.AUDITS],
  //  [REC]: [Routes.MARKETING_BUDGET, Routes.MARKETING_BUDGETS],
  [AEG]: [],
  [-1]: [
    Routes.STORE,
    Routes.STORES,
    Routes.ONLINE_REPORTING,
    Routes.DISTRIBUTION_CHANNELS,
    Routes.USERS,
    Routes.CAMPAIGNS,
  ],
  [MIELE]: [
    Routes.HOME,
    Routes.REGISTRATIONS,
    Routes.PRODUCTS,
    Routes.STORE,
    Routes.STORES,
    Routes.ONLINE_REPORTING,
    Routes.DISTRIBUTION_CHANNELS,
    Routes.USERS,
    Routes.CAMPAIGNS,
  ],
  [LG]: [
    Routes.HOME,
    Routes.REGISTRATIONS,
    Routes.PRODUCTS,
    Routes.STORE,
    Routes.STORES,
    Routes.ONLINE_REPORTING,
    Routes.DISTRIBUTION_CHANNELS,
    Routes.USERS,
    Routes.CAMPAIGNS,
  ],
  [REC]: [
    Routes.HOME,
    Routes.REGISTRATIONS,
    Routes.PRODUCTS,
    Routes.STORE,
    Routes.STORES,
    Routes.ONLINE_REPORTING,
    Routes.DISTRIBUTION_CHANNELS,
    Routes.USERS,
    Routes.CAMPAIGNS,
  ],
};

// export const REGISTER = {
//   path: Routes.REGISTER,
//   redirectAction: (project: ProjectJSON) =>
//     push({
//       pathname: Routes.REGISTER.replace(':id', `${project.id}`),
//       state: {
//         project,
//       },
//     }),
// };

export function getAvailableRoutesToSob(sob?: SalesOrgBrand): Routes[] {
  return AVAILABLE_ROUTES_BY_SOB[sob || getSalesOrgBrandId()];
}

export function getAvailableRoutesQuantityToSob(sob?: SalesOrgBrand): number {
  return size(getAvailableRoutesToSob(sob));
}

export function getHasMoreThanoneAvailableRouteToSob(): boolean {
  return getAvailableRoutesQuantityToSob() > 1;
}

export function canSobNavigateToRoute(
  route: Routes,
  sob?: SalesOrgBrand,
): boolean {
  const availableRoutes = getAvailableRoutesToSob(sob);
  if (!availableRoutes) return false;
  return Object.values(availableRoutes).includes(route);
}

export function canSobNavigateToDashboard(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.HOME, sob);
}
export function canSobNavigateToAudits(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.AUDITS, sob);
}
export function canSobNavigateToStores(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.STORES, sob);
}
export function canSobNavigateToStore(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.STORE, sob);
}

export function canSobNavigateToIbvs(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.IBVS, sob);
}

export function canSobNavigateToMarketingBudgets(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.MARKETING_BUDGETS, sob);
}

export function canSobNavigateToMarketingBudget(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.MARKETING_BUDGET, sob);
}

export function canSobNavigateToOnlineReporting(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.ONLINE_REPORTING, sob);
}

export function canSobNavigateToDistributionChannels(
  sob?: SalesOrgBrand,
): boolean {
  return canSobNavigateToRoute(Routes.DISTRIBUTION_CHANNELS, sob);
}

export function canSobNavigateToUsers(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.USERS, sob);
}

export function canSobNavigateToCampaigns(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.CAMPAIGNS, sob);
}

export function canSobNavigateToRegistrations(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.REGISTRATIONS, sob);
}

export function canSobNavigateToProducts(sob?: SalesOrgBrand): boolean {
  return canSobNavigateToRoute(Routes.PRODUCTS, sob);
}

export function useCanLoggedSOBNavigateTo(route: Routes) {
  const loggedUserSOBIDs = useLoggedUserSalesOrgBrandIDs();
  return some(loggedUserSOBIDs, loggedUserSOBID =>
    canSobNavigateToRoute(route, loggedUserSOBID),
  );
}
export default Routes;
