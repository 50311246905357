import { isDevelopment } from '@mmw/environment';
import logger from '@mmw/logging-logger';
// XXX: removed in favor of redux dev tools
// import { mountStoreDevtool } from 'simple-zustand-devtools';
import { F, U } from '@utils/ts';
import { updatedDiff } from 'deep-object-diff';
import { cloneDeep, each, join, set } from 'lodash';
import compact from 'lodash/compact';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import { useCallback, useEffect, useMemo } from 'react';
import { StateCreator } from 'zustand';

import { createSelector, createStoreModule } from '../module';
import { CreateReturnType, StoreConfig } from '../types';

interface Options<Data, Request = undefined> {
  onSuccess?: F.Function<[Data, Request | undefined]>;
  onError?: F.Function<[Error, Request | undefined]>;
}

interface PaginateRequest {
  offset: number;
  limit: number;
}

type AsyncFunction<Data, Request = undefined> = (
  request: Request | undefined,
) => Promise<Data>;

export class AsyncState<Data, Request = undefined> {
  data: Data | null = null;

  request: Request | null = null;

  loading = false;

  error: Error | null = null;

  fetch: (
    request: Request | undefined,
    options?: Options<Data, Request>,
  ) => void = noop;

  reset: () => void = noop;
}

const getInitializer: <Data, Request = undefined>(
  initialState: AsyncState<Data, Request>,
  asyncFunction: AsyncFunction<Data, Request>,
) => StateCreator<AsyncState<Data, Request>> =
  (initialState, asyncFunction) => (setState, getState) => {
    const state = initialState;
    state.reset = () => setState(initialState);
    state.fetch = async (request, options) => {
      setState({ loading: true });
      try {
        const result = await asyncFunction(request);
        setState({ data: result, loading: false, error: null, request });
        if (options?.onSuccess) {
          options?.onSuccess(result, request);
        }
      } catch (e) {
        setState({ error: e, loading: false, request });
        if (options?.onError) {
          options?.onError(e, request);
        }
      }
    };
    return initialState;
  };

export function createAsyncStoreModule<Data, Request = undefined>({
  asyncFunction,
  name,
  disablePersist,
}: {
  asyncFunction: AsyncFunction<Data, Request>;
  name: string;
  disablePersist?: boolean;
}) {
  const initialState = new AsyncState<Data, Request>();

  const STORE_CONFIG = new StoreConfig<AsyncState<Data, Request>>({
    name,
  });

  const module = createStoreModule<AsyncState<Data, Request>>({
    ...STORE_CONFIG,
    initializer: getInitializer(initialState, asyncFunction),
    disablePersist,
  });

  if (isDevelopment()) {
    // mountStoreDevtool(name, module);
    const log = logger.extend(`zustand-store:async-module:${name}`);
    module.subscribe((state, prevState) => {
      if (state.error != null && !isEmpty(state.error)) {
        // alerting(state.error);
        log.error(`store error for = ${name}`, state.error);
      } else {
        log.info(
          `store state changed for = ${name}, state = `,
          updatedDiff(prevState, state),
        );
      }
    });
  }

  return module;
}

export interface Selectors<Data, Request = undefined> {
  loading: (
    state: AsyncState<Data, Request>,
  ) => AsyncState<Data, Request>['loading'];
  data: (state: AsyncState<Data, Request>) => AsyncState<Data, Request>['data'];
  request: (
    state: AsyncState<Data, Request>,
  ) => AsyncState<Data, Request>['request'];
  fetch: (
    state: AsyncState<Data, Request>,
  ) => AsyncState<Data, Request>['fetch'];
  reset: (
    state: AsyncState<Data, Request>,
  ) => AsyncState<Data, Request>['reset'];
  error: (
    state: AsyncState<Data, Request>,
  ) => AsyncState<Data, Request>['error'];
}

export const SELECTORS: Selectors<any, any> = {
  loading: createSelector('loading'),
  data: createSelector('data'),
  request: createSelector('request'),
  fetch: createSelector('fetch'),
  reset: createSelector('reset'),
  error: createSelector('error'),
};

interface StateHooks<Data, Request = undefined> {
  useLoading: F.Function<[], AsyncState<Data, Request>['loading']>;
  useData: F.Function<[], AsyncState<Data, Request>['data']>;
  useRequest: F.Function<[], AsyncState<Data, Request>['request']>;
  useFetch: F.Function<[], AsyncState<Data, Request>['fetch']>;
  useFetchOnMount: F.Function<[Request?], void>;
  useFetchOnMountWhenEmpty: F.Function<[Request?], void>;
  useFetchOnMountWhenRequestIsReady: F.Function<[Request?], void>;
  useReset: F.Function<[], AsyncState<Data, Request>['reset']>;
  useError: F.Function<[], AsyncState<Data, Request>['error']>;
  usePaginate: F.Function<[], (params: PaginateRequest) => void>;
}
type StateHooksArray<Data, Request = undefined> = [
  StateHooks<Data, Request>['useData'],
  StateHooks<Data, Request>['useLoading'],
  StateHooks<Data, Request>['useError'],
  StateHooks<Data, Request>['useFetch'],
  StateHooks<Data, U.Nullable<Request>>['useFetchOnMount'],
  StateHooks<Data, Request>['useRequest'],
  StateHooks<Data, Request>['useReset'],
  StateHooks<Data, U.Nullable<Request>>['useFetchOnMountWhenEmpty'],
  StateHooks<Data, U.Nullable<Request>>['useFetchOnMountWhenRequestIsReady'],
  StateHooks<Data, Request>['usePaginate'],
];

export function createAsyncStoreHooks<Data, Request = undefined>(
  useStoreModule: CreateReturnType<AsyncState<Data, Request>>,
  config?: {
    requirePayloadOnMount: boolean;
    paginateConfig?: {
      paginateRequestKeys: string[];
      requestPath: string;
      defaultLimit: number;
    };
  },
): StateHooks<Data, Request> {
  const useFetchInternal = () => useStoreModule(SELECTORS.fetch);

  function useFetch() {
    const fetch = useFetchInternal();
    const limitPath = join(
      compact([config?.paginateConfig?.requestPath, 'limit']),
      '.',
    );
    return useCallback(
      (request: U.Nullable<Request>, options?: Options<Data, Request>) => {
        if (
          config?.paginateConfig?.defaultLimit &&
          !get(request, limitPath) &&
          request
        ) {
          const requestClone = cloneDeep(request);
          set(requestClone, limitPath, config?.paginateConfig?.defaultLimit);
          fetch(request, options);
        } else fetch(request, options);
      },
      [fetch, limitPath],
    );
  }
  const useData = () => useStoreModule(SELECTORS.data);

  function useFetchOnMount(request: U.Nullable<Request>) {
    const fetch = useFetch();
    useEffect(() => {
      if (config?.requirePayloadOnMount && !!request) {
        fetch(request);
      } else if (!config?.requirePayloadOnMount) {
        fetch(request);
      }
    }, [fetch, request]);
  }

  function useFetchOnMountWhenEmpty(request: U.Nullable<Request>) {
    const fetch = useFetch();
    const data = useData();
    useEffect(() => {
      if (
        isEmpty(data) &&
        config?.requirePayloadOnMount &&
        !!request &&
        !isEmpty(request)
      ) {
        fetch(request);
      } else if (isEmpty(data) && !config?.requirePayloadOnMount) {
        fetch(request);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetch, request]);
  }

  function useFetchOnMountWhenRequestIsReady(request: U.Nullable<Request>) {
    const fetch = useFetch();
    const isReady = useMemo(() => {
      if (isObject(request)) {
        return every(request, value => !isUndefined(value));
      }
      return !isUndefined(request);
    }, [request]);
    useEffect(() => {
      if (config?.requirePayloadOnMount && isReady) {
        fetch(request);
      }
    }, [isReady, fetch]);
  }
  const useRequest = () => useStoreModule(SELECTORS.request);

  function usePaginate() {
    const request = useRequest();
    const fetch = useFetch();
    return useCallback(
      (paginateRequest: PaginateRequest, options?: Options<Data, Request>) => {
        const lastFullRequest = cloneDeep(request);
        each(config?.paginateConfig?.paginateRequestKeys, key => {
          set(
            lastFullRequest,
            join(compact([config?.paginateConfig?.requestPath, key]), '.'),
            paginateRequest[key],
          );
        });
        fetch(lastFullRequest, options);
      },
      [fetch, request],
    );
  }

  return {
    useData: () => useStoreModule(SELECTORS.data),
    useLoading: () => useStoreModule(SELECTORS.loading),
    useError: () => useStoreModule(SELECTORS.error),
    useFetch,
    useFetchOnMount,
    useFetchOnMountWhenEmpty,
    useFetchOnMountWhenRequestIsReady,
    useRequest,
    useReset: () => useStoreModule(SELECTORS.reset),
    usePaginate,
  };
}

export const DEFAULT_PAGINATION_CONFIG = {
  paginateRequestKeys: ['limit', 'offset'],
  requestPath: 'request',
  defaultLimit: 10,
};

export function createAsyncStoreHooksArray<Data, Request = undefined>(
  useStoreModule: CreateReturnType<AsyncState<Data, Request>>,
  config?: {
    requirePayloadOnMount: boolean;
    paginateConfig?: {
      paginateRequestKeys: string[];
      requestPath: string;
      defaultLimit: number;
    };
  },
): StateHooksArray<Data, Request> {
  const {
    useLoading,
    useFetchOnMount,
    useFetchOnMountWhenEmpty,
    useFetchOnMountWhenRequestIsReady,
    useRequest,
    useReset,
    useError,
    useData,
    useFetch,
    usePaginate,
  } = createAsyncStoreHooks(useStoreModule, config);

  return [
    useData,
    useLoading,
    useError,
    useFetch,
    useFetchOnMount,
    useRequest,
    useReset,
    useFetchOnMountWhenEmpty,
    useFetchOnMountWhenRequestIsReady,
    usePaginate,
  ];
}
