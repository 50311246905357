import { BaseUsernameForm, EMAIL_VALIDATION_SCHEMA } from '@auth/form-data';
import { UserField } from '@auth/view-form-components';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { CANCEL, RECOVER } from '@mmw/constants-i18n-buttons';
import { PERSON as PERSON_I18N } from '@mmw/constants-i18n-fields';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

const INITIAL_VALUES = new BaseUsernameForm();

const {
  EMAIL: { LABEL: EMAIL_LABEL_I18N },
} = PERSON_I18N;

interface Props {
  onInformUserMailSuccess: F.Function<[BaseUsernameForm]>;
  onCancel?: F.Function;
  loading?: boolean;
}

const InformEmail: React.FC<Props> = ({
  onInformUserMailSuccess,
  onCancel,
  loading,
}: Props) => (
  <FastFormProvider
    initialValues={INITIAL_VALUES}
    // @ts-ignore
    validationSchema={EMAIL_VALIDATION_SCHEMA}
    onSubmitSuccess={onInformUserMailSuccess}
  >
    <UI.Container direction="column" w="100%" gap={4}>
      <UserField
        label={EMAIL_LABEL_I18N}
        accessoryLeft={
          <UI.Icon name="person" size={20} color="primary.light" />
        }
      />
      <UI.Container
        w="100%"
        justify={onCancel ? 'space-between' : 'flex-start'}
      >
        {onCancel ? (
          <UI.Button i18n={CANCEL} onClick={onCancel} modifiers="secondary" />
        ) : null}
        <FastFormSubmitButton
          style={css`
            max-width: 180px;
          `}
          i18n={RECOVER}
          disabled={loading}
        />
      </UI.Container>
    </UI.Container>
  </FastFormProvider>
);

export default InformEmail;
