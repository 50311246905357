import {
  ACCOUNT_CREATION_VALIDATION_SCHEMA,
  AccountCreationForm,
} from '@auth/form-data';
import { AuthErrorHandler } from '@auth/view-error';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React, { PropsWithChildren } from 'react';
import { F } from 'ts-toolbelt';
import { ObjectSchema } from 'yup';

const INITIAL_VALUES = new AccountCreationForm();
// const VALIDATION_SCHEMA = ACCOUNT_CREATION_VALIDATION_SCHEMA.shape({
//   [ACCOUNT_CREATION_FIELDPATHS.country.$path]: UTILS.STRING_REQUIRED,
// });

// const VALIDATION_SCHEMA = object<FormValues>().shape({
//   [FIELD_PATHS.email.$path]: USER_REQUIRED,
//   [FIELD_PATHS.password.$path]: PASSWORD_REQUIRED,
//   [FIELD_PATHS.repeatPassword.$path]: PASSWORD_REQUIRED,
//   [FIELD_PATHS.country.$path]: ADDRESS.COUNTRY_REQUIRED,
// });

// const AUTH_ERROR_HANDLER_CONFIG = {
//   [PossibleErrors.BAD_CREDENTIALS]: ErrorNotification,
// };

type Props = {
  onSuccess?: F.Function;
  customErrorConfigs?: Record<string, React.FC>;
  customValidationSchema?: ObjectSchema<any>;
};

const Form: React.FC<PropsWithChildren<Props>> = ({
  onSuccess,
  customErrorConfigs,
  customValidationSchema,
  children,
}: PropsWithChildren<Props>) => (
  // const errorHandlerConfigs = useMemo(() => {
  //   if (!isEmpty(customErrorConfigs)) {
  //     return {
  //       ...AUTH_ERROR_HANDLER_CONFIG,
  //       ...customErrorConfigs,
  //     };
  //   }
  //   return AUTH_ERROR_HANDLER_CONFIG;
  // }, [customErrorConfigs]);

  <FastFormProvider
    // @ts-ignore
    validationSchema={
      customValidationSchema || ACCOUNT_CREATION_VALIDATION_SCHEMA
    }
    initialValues={INITIAL_VALUES}
    onSubmitSuccess={onSuccess}
  >
    <UI.Container direction="column" gap={3} w="100%">
      {children}
      <AuthErrorHandler
        style={css`
          border-radius: 20px;
          margin-bottom: 20px;
        `}
        config={customErrorConfigs}
      />
    </UI.Container>
  </FastFormProvider>
);
export default Form;
