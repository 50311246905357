import {
  PRODUCT as FIELDS,
  PROJECT as PROJECT_FIELDS,
} from '@mmw/constants-fields-names';

const labelNamespace = 'label';
const placeholderNamespace = 'placeholder';

const PRODUCT = {
  PRODUCT: {
    LABEL: {
      key: `${labelNamespace}.product`,
      message: 'Product',
    },
  },
  ACCESSORY: {
    LABEL: {
      key: `${labelNamespace}.accessory`,
      message: 'Accessory',
    },
  },
  PRODUCTS: {
    LABEL: {
      key: `${labelNamespace}.products`,
      message: 'Products',
    },
  },
  NAME: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.NAME}`,
      message: 'Name',
    },
  },
  ORDERNUMBER: {
    LABEL: {
      key: `${labelNamespace}.${PROJECT_FIELDS.PRODUCT.ORDER_NO}`,
      message: 'Order number',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.informDeliveryNumber`,
      message: 'Inform delivery number',
    },
    SUCCESS_SUB: {
      key: `${labelNamespace}.deliveryNumberVerificationSuccess`,
      message: 'The delivery number exists on database',
    },
    FAILURE_SUB: {
      key: `${labelNamespace}.deliveryNumberVerificationFailure`,
      message: 'The delivery number does not exist on database',
    },
  },
  DELIVERYNUMBER: {
    LABEL: {
      key: `${labelNamespace}.${PROJECT_FIELDS.PRODUCT.DELIVERY_NO}`,
      message: 'Delivery number',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.informDeliveryNumber`,
      message: 'Inform delivery number',
    },
    SUCCESS_SUB: {
      key: `${labelNamespace}.deliveryNumberVerificationSuccess`,
      message: 'The delivery number exists on database',
    },
    FAILURE_SUB: {
      key: `${labelNamespace}.deliveryNumberVerificationFailure`,
      message: 'The delivery number does not exist on database',
    },
  },
  PALLETNUMBER: {
    LABEL: {
      key: `${labelNamespace}.${PROJECT_FIELDS.PRODUCT.PALLET_NO}`,
      message: 'Pallet number',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.informPalletNumber`,
      message: 'Inform pallet number',
    },
    SUCCESS_SUB: {
      key: `${labelNamespace}.palletNumberVerificationSuccess`,
      message: 'The pallet number exists on database',
    },
    FAILURE_SUB: {
      key: `${labelNamespace}.palletNumberVerificationFailure`,
      message: 'The pallet number does not exist on database',
    },
  },
  SERIALNUMBER: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.SERIALNUMBER}`,
      message: 'Serialnumber',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.informSerialNumber`,
      message: 'Inform serial number',
    },
    SUCCESS_SUB: {
      key: `${labelNamespace}.serialNumberVerificationSuccess`,
      message: 'The serial number exists on database',
    },
    FAILURE_SUB: {
      key: `${labelNamespace}.serialNumberVerificationFailure`,
      message: 'The serial number does not exist on database',
    },
    REMOVED_SERIALNUMBER_INITIALS_ALERT: {
      key: `${labelNamespace}.removingInitialSerialNumberDigits`,
      message:
        'The following digits will be removed from the start of serial number:',
    },
    SN_LABEL: {
      key: `${labelNamespace}.${FIELDS.SERIALNUMBER}.SN`,
      message: 'S/N',
    },
  },
  ARTICLE: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.ARTICLE}`,
      message: 'Article number',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.articleNumber`,
      message: 'Article number',
    },
  },
  COUPON: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.COUPON}`,
      message: 'Coupon Number',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.informCouponNumber`,
      message: 'Inform your coupon number',
    },
  },
  IMEI: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.IMEI}`,
      message: 'Imei',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.informImei`,
      message: 'Inform IMEI',
    },
  },
  MODEL_NAME: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.MODEL_NAME}`,
      message: 'Model name',
    },
  },
  EAN: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.EAN}`,
      message: 'EAN',
    },
  },
  MATERIAL_KEY: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.MATERIAL_KEY}`,
      message: 'Item number',
    },
  },
  NAME_OR_EAN: {
    PLACEHOLDER: {
      key: `${placeholderNamespace}.searchByEANOrName`,
      message: 'Inform name or EAN',
    },
  },
  SOLD_QUANTITY: {
    LABEL: {
      key: `${labelNamespace}.soldQuantity`,
      message: 'Sold quantity',
    },
  },
  CLASS_OF_GOOD: {
    LABEL: {
      key: `${labelNamespace}.classOfGood`,
      message: 'Class of good',
    },
  },
  PRODUCT_NAME: {
    LABEL: {
      key: `${labelNamespace}.productName`,
      message: 'Product name',
    },
  },
};

export default PRODUCT;
