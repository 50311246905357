import { JsonView } from '@dev/core';
import { HandleBaseRedirect } from '@lib/navigation';
import { initialize, listenForHistory } from '@mmw/common-ga';
import { getStaticUrl } from '@mmw/config';
import contextualConfig, { getContextPath } from '@mmw/contextual-config';
import { isDevelopment } from '@mmw/environment';
import { useIsLoggedIn } from '@mmw/redux-store-auth-api-authentication/hooks';
import {
  InformUserIdPage as RecoverPasswordUserIdStep,
  NewPasswordByUUIDPage,
} from '@mmw/shared-pages';
import {
  ConnectedRouter,
  history,
  Redirect,
  Route,
  ScrollToTop,
  Switch,
} from '@mmw/ui-web-libraries-react-router';
import { MetaTags } from '@retail/configure-meta-tags';
import PATHS from '@retail/constants-routes/constants';
import React from 'react';
import ReactGTM, { TagManagerArgs } from 'react-gtm-module';

import Authentication from './pages/authentication';
// import RecoverPassword from './pages/authentication/RecoverPassword';
import RecoverUsername from './pages/authentication/RecoverUsername';
import SSO from './pages/authentication/SingleSignOn';

const CONTEXT_PATH = getContextPath();
const fontUrl = `${getStaticUrl()}/fonts/OpenSans/index.css`;

initialize();
listenForHistory(history);

ReactGTM.initialize({
  gtmId: contextualConfig.googleTagManager?.gtmId,
} as TagManagerArgs);

const RECOVER_ROUTES = [
  PATHS.RECOVER_PASSWORD,
  PATHS.RECOVER_PASSWORD_ROOT,
  PATHS.RECOVER_USERNAME,
];

const OpenedRoutes: React.FC = () => {
  const isLoggedIn = useIsLoggedIn();
  return (
    <Switch>
      <Route path={PATHS.HOME} exact>
        {!isLoggedIn ? <Authentication /> : <Redirect to={PATHS.LOGIN} />}
      </Route>
      <Route path={PATHS.SSO} exact>
        <SSO />
      </Route>
      <Route path={PATHS.LOGIN} exact>
        <Authentication />
      </Route>
      <Route path={PATHS.RECOVER_PASSWORD_ROOT} exact>
        <RecoverPasswordUserIdStep />
      </Route>
      <Route path={PATHS.RECOVER_PASSWORD}>
        <NewPasswordByUUIDPage />
      </Route>
      <Route path={PATHS.RECOVER_USERNAME}>
        <RecoverUsername />
      </Route>
      {!isDevelopment() ? (
        <Route path="*" render={() => <Redirect to="/" />} />
      ) : null}
    </Switch>
  );
};

const Navigation: React.FC = () => (
  <>
    <JsonView />
    <MetaTags fontUrl={fontUrl} />
    {/* @ts-ignore */}
    <ConnectedRouter basename={`${CONTEXT_PATH}/login`} history={history}>
      <HandleBaseRedirect />
      <ScrollToTop>
        <OpenedRoutes />
      </ScrollToTop>
    </ConnectedRouter>
  </>
);

export default Navigation;
