import { StyledProps } from '@mmw/native-theme-v2/types';
import { Theme } from '@mmw/ui-theme/types';

export const NAMESPACE = 'acceptanceModal';

export default {
  content: {
    style: ({ theme }: StyledProps<Theme>): string => `
      max-width: 80vw;
      overflow-x: auto;
      ${theme.media.phone`
        max-width: 100vw;
        height: 82vh;
        flex-direction: column;
        padding: 20px;
      `};
    `,
  },
  containers: {
    buttons: {
      style: ({ theme }: StyledProps<Theme>): string => `
        display: flex;
        ${theme.media.phone`
          flex-direction: column;
          padding: 15px;
          margin-top: auto;
        `};
      `,
    },
    actions: {
      style: (): string => `
        margin-top: auto;
        width: 100%;
      `,
    },
  },
};
