import { I18nShape } from '@mmw/constants-i18n';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';
import { U } from 'ts-toolbelt';

interface Props {
  titleI18n?: U.Nullable<string | I18nShape>;
  descI18n?: U.Nullable<string | I18nShape>;
  backActionContent?: U.Nullable<React.ReactElement<any>>;
}

const AuthCard: React.FC<React.PropsWithChildren<Props>> = ({
  titleI18n,
  descI18n,
  children,
  backActionContent,
}: React.PropsWithChildren<Props>) => (
  <UI.Card
    style={css`
      border-radius: 20px;
      width: 560px;
      padding: 30px;
    `}
    elevation={10}
  >
    <UI.Container direction="column" gap={4} w="100%">
      <UI.Container align="flex-start" justify="space-between" w="100%">
        <UI.Container direction="column" gap={3}>
          <UI.Typography modifiers="primary" variant="h4" i18n={titleI18n} />
          {descI18n ? <UI.Typography variant="body2" i18n={descI18n} /> : null}
        </UI.Container>
        {backActionContent}
      </UI.Container>
      <UI.Container w="100%">{children}</UI.Container>
    </UI.Container>
  </UI.Card>
);

export default AuthCard;
