import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  ChangePasswordErrorAction,
  ChangePasswordStartAction,
  ChangePasswordSuccessAction,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_START,
  RECOVER_PASSWORD_SUCCESS,
  RecoverPasswordErrorAction,
  RecoverPasswordStartAction,
  RecoverPasswordSuccessAction,
  recoverResponse,
  RESET,
  ResetAction,
  VALIDATE_CHANGE_PASSWORD_UUID_ERROR,
  VALIDATE_CHANGE_PASSWORD_UUID_START,
  VALIDATE_CHANGE_PASSWORD_UUID_SUCCESS,
  ValidateChangePasswordUuidErrorAction,
  ValidateChangePasswordUuidStartAction,
  ValidateChangePasswordUuidSuccessAction,
} from './types';

export const recoverPasswordStartAction = (
  userid: string,
  language: string,
): RecoverPasswordStartAction => ({
  type: RECOVER_PASSWORD_START,
  payload: {
    userid,
    language,
  },
});

export const recoverPasswordSuccessAction = (
  response: recoverResponse,
): RecoverPasswordSuccessAction => ({
  type: RECOVER_PASSWORD_SUCCESS,
  payload: response,
});

export const recoverPasswordErrorAction = (
  error: Error | null,
): RecoverPasswordErrorAction => ({
  type: RECOVER_PASSWORD_ERROR,

  payload: {
    error,
  },
});

export const changePasswordStartAction = (
  uuid,
  password,
  language,
  onSuccess?: () => void,
): ChangePasswordStartAction => ({
  type: CHANGE_PASSWORD_START,
  payload: {
    uuid,
    language,
    password,
    onSuccess,
  },
});

export const changePasswordSuccessAction = (
  response,
): ChangePasswordSuccessAction => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: response,
});

export const changePasswordErrorAction = (
  error: Error | null,
): ChangePasswordErrorAction => ({
  type: CHANGE_PASSWORD_ERROR,

  payload: {
    error,
  },
});

export const validateChangePasswordUuidStartAction = (
  uuid,
): ValidateChangePasswordUuidStartAction => ({
  type: VALIDATE_CHANGE_PASSWORD_UUID_START,

  payload: {
    uuid,
  },
});

export const validateChangePasswordUuidSuccessAction = (
  response,
): ValidateChangePasswordUuidSuccessAction => ({
  type: VALIDATE_CHANGE_PASSWORD_UUID_SUCCESS,
  payload: response,
});

export const validateChangePasswordUuidErrorAction = (
  error: Error | null,
): ValidateChangePasswordUuidErrorAction => ({
  type: VALIDATE_CHANGE_PASSWORD_UUID_ERROR,

  payload: {
    error,
  },
});

export const resetAction = (): ResetAction => ({
  type: RESET,
});
