import { NewPasswordForm } from '@auth/form-data';
import { OperationsEnum } from '@mmw/services-auth-api-authentication/types';
import { F, U } from 'ts-toolbelt';

export enum PasswordRedefinitionSteps {
  INFORM_EMAIL = 'email',
  TAN_CODE_VALIDATION = 'tan',
  NEW_PASSWORD = 'password',
}

export interface ContentProps {
  step: U.Nullable<PasswordRedefinitionSteps>;
  onInformUserMailSuccess: F.Function;
  onTanCodeVerifySuccess: F.Function;
  onResendCodeRequest: F.Function;
  onSubmitNewPassword: F.Function;
  onCancelOperation?: F.Function;
  operationInProcess?: boolean;
}

export type PasswordChangeStepControlType = {
  currentStep: U.Nullable<PasswordRedefinitionSteps>;
  setNextStep: F.Function<[U.Nullable<PasswordRedefinitionSteps>]>;
};

export interface PasswordChangeProps extends PasswordChangeStepControlType {
  email?: U.Nullable<string>;
  onSubmitNewPassword: F.Function<[NewPasswordForm]>;
  onSuccess?: F.Function;
  onCancel?: F.Function;
  successStatus?: U.Nullable<boolean>;
  operation: OperationsEnum;
  operationInProcess?: boolean;
}
