import { I18nShape } from '@mmw/constants-i18n';
import { ACCEPT, DECLINE } from '@mmw/constants-i18n-buttons';
import { MORE_INFORMATION } from '@mmw/constants-i18n-common';
import { PerfectScroll } from '@perfect-scroll/core';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import { useIsMediaQueryUpToSM } from '@ui-system/media-query';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { F } from '@utils/ts';
import { useBoolean } from 'ahooks';
import map from 'lodash/map';
import * as React from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDeepCompareEffect } from 'use-deep-compare';

import { COOKIE_CONSENT } from './i18n';

interface CookieItem {
  displayName: string | I18nShape;
  key: string;
  value: boolean;
  description: string | I18nShape;
}

const ANALYTICS_NAMESPACE = 'analytics';
const THIRD_PARTY_NAMESPACE = 'third-party';

const COOKIE_ITEMS = [
  {
    displayName:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.GOOGLE_ANALYTICS_CONSENT.TITLE,
    key: ANALYTICS_NAMESPACE,
    value: true,
    description:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.GOOGLE_ANALYTICS_CONSENT.CONTENT,
  },
  {
    displayName:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.THIRD_PARTY_CONSENT.TITLE,
    key: THIRD_PARTY_NAMESPACE,
    value: true,
    description:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.THIRD_PARTY_CONSENT.CONTENT,
  },
];

export const LOCAL_STORAGE_KEY = 'accepted-cookies';

const useStyle = makeStyle<{ selected: boolean }>(
  ({ theme, props }) => css`
    padding: 10p 20px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `,
);

interface CookieItemComponentProps {
  item: CookieItem;
  style?: Style;
  selected: boolean;
  onSelect: F.Function<[string]>;
}

const CookieItemComponent: React.FC<CookieItemComponentProps> = ({
  item,
  style,
  selected,
  onSelect,
}: CookieItemComponentProps) => {
  const borderStyle = useStyle({ selected });
  return (
    <UI.Card style={style} square clickable>
      <UI.Card.Content style={borderStyle} onClick={() => onSelect(item.key)}>
        <UI.Container
          direction="row"
          w="100%"
          align="center"
          justify="space-between"
        >
          <UI.Typography i18n={item.displayName} />
          <UI.Icon name="down" size={15} />
        </UI.Container>
      </UI.Card.Content>
      <UI.Card.CollapseArea in={selected}>
        <UI.Container p={4}>
          <UI.Caption i18n={item.description} />
        </UI.Container>
      </UI.Card.CollapseArea>
    </UI.Card>
  );
};

CookieItemComponent.defaultProps = {
  style: {},
};

const CONTENT_STYLE = { maxHeight: '90vh' };
const CONTENT_STYLE_MOBILE = { maxHeight: '90vh', maxWidth: '100vw' };
const MODAL_STYLE = { width: 400, margin: '0 auto' };
const MODAL_STYLE_MOBILE = { width: '100%', margin: '0 auto' };

interface CookieConsentProps {
  visible?: boolean;
  onAccept?: F.Function;
  onDecline: F.Function;
  moreInformationUrl?: string;
}

export const COOKIES = [
  LOCAL_STORAGE_KEY,
  ANALYTICS_NAMESPACE,
  THIRD_PARTY_NAMESPACE,
];

export const CookieConsent: React.FC<CookieConsentProps> = ({
  visible,
  onAccept,
  onDecline,
  moreInformationUrl,
}: CookieConsentProps) => {
  const [isOpen, { toggle, setTrue: show }] = useBoolean();
  const [selected, setSelected] = useState<string | null>(null);
  const isMobile = useIsMediaQueryUpToSM();
  const width = isMobile ? '100%' : 400;
  const [cookies, setCookie, removeCookie] = useCookies(COOKIES);

  const onSaveAll = useCallback(() => {
    setCookie(ANALYTICS_NAMESPACE, true);
    setCookie(THIRD_PARTY_NAMESPACE, true);
    setCookie(LOCAL_STORAGE_KEY, true);
    if (onAccept) {
      onAccept();
    }
    toggle();
  }, [onAccept, setCookie, toggle]);

  useDeepCompareEffect(() => {
    if (
      cookies['accepted-cookies'] === 'false' ||
      !cookies['accepted-cookies']
    ) {
      show();
      removeCookie(LOCAL_STORAGE_KEY);
      removeCookie(ANALYTICS_NAMESPACE);
      removeCookie(THIRD_PARTY_NAMESPACE);
    }
  }, [cookies, removeCookie, show]);

  useLayoutEffect(() => {
    if (visible) {
      removeCookie(LOCAL_STORAGE_KEY);
      removeCookie(ANALYTICS_NAMESPACE);
      removeCookie(THIRD_PARTY_NAMESPACE);
    }
  }, [visible]);

  return (
    <UI.Modal
      visible={visible || isOpen}
      style={isMobile ? MODAL_STYLE_MOBILE : MODAL_STYLE}
    >
      <UI.Container
        direction="column"
        style={isMobile ? CONTENT_STYLE_MOBILE : CONTENT_STYLE}
        align="center"
        justify="center"
        bg="white"
      >
        <PerfectScroll>
          <UI.Container direction="column" gap={2} bg="white" w={width} p={4}>
            <UI.Container>
              <UI.Typography
                i18n={COOKIE_CONSENT.CONFIG.TITLE}
                modifiers="bold"
              />
            </UI.Container>
            <UI.Container direction="column" gap={1}>
              <UI.Typography
                i18n={COOKIE_CONSENT.CONFIG.TEXT}
                variant="caption"
              />
              {moreInformationUrl ? (
                <UI.Link
                  href={moreInformationUrl}
                  i18n={MORE_INFORMATION}
                  modifiers="underline"
                />
              ) : null}
            </UI.Container>
            <UI.Container direction="column" gap={2}>
              <UI.Container m="1, 0">
                <UI.Typography
                  i18n={COOKIE_CONSENT.CONFIG.CONTENT_TITLE}
                  modifiers="bold"
                />
              </UI.Container>
              <UI.Container direction="column" f={1} gap={1}>
                {map(COOKIE_ITEMS, item => (
                  <CookieItemComponent
                    item={item}
                    key={item.key}
                    selected={selected === item.key}
                    onSelect={(key: string) =>
                      setSelected(prev => (key === prev ? null : key))
                    }
                  />
                ))}
              </UI.Container>
            </UI.Container>
          </UI.Container>
        </PerfectScroll>
        <UI.Container justify="space-between" bg="white" w={width} p={4}>
          <UI.Button onClick={onDecline} i18n={DECLINE} modifiers="secondary" />
          <UI.Button onClick={onSaveAll} i18n={ACCEPT} />
        </UI.Container>
      </UI.Container>
    </UI.Modal>
  );
};
