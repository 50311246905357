import contextualConfig from '@mmw/contextual-config';
import ofType, { BatchAction } from '@mmw/redux-rx-of-type-operator';
import {
  authenticateErrorAction,
  authenticateSuccessAction,
} from '@mmw/redux-store-auth-api-authentication/actions';
import {
  RecaptchaType,
  SecurityScopeNames,
} from '@mmw/services-auth-api-authentication/types';
import { getAuthenticationService } from '@mmw/services-holder';
import { isEmail as emailRegexTest } from '@mmw/utils-email';
import isObject from 'lodash/isObject';
import noop from 'lodash/noop';
import { batchActions } from 'redux-batched-actions';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import { F, U } from 'ts-toolbelt';

import {
  loginByPasswordErrorAction,
  loginByPasswordSuccessAction,
} from '../actions';
import { recaptchaResponseSelector, usernameSelector } from '../stateSelector';
import {
  AuthenticationResponseWithUser,
  LOGIN_BY_PASSWORD_START,
  LoginByPasswordErrorAction,
  LoginByPasswordStartAction,
  LoginByPasswordSuccessAction,
  RootState,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

export type EpicCreatorConfig = {
  onSuccess?: F.Function<[AuthenticationResponseWithUser]>;
};

type AuthenticationOptions = {
  username: string;
  password: string;
  applicationId?: U.Nullable<string>;
  recaptchaResponse?: U.Nullable<string>;
  recaptchaType?: U.Nullable<RecaptchaType>;
  applicationBaseUrl?: U.Nullable<string>;
  applicationPath?: U.Nullable<string>;
  applicationContextPath?: U.Nullable<string>;
  scopeName?: SecurityScopeNames;
};

const authenticate = async (
  options: AuthenticationOptions,
  scopeNames?: SecurityScopeNames[],
): Promise<AuthenticationResponseWithUser> => {
  logger.debug('Will authenticate with', options);
  let authParams = options;
  const isEmail = emailRegexTest(options.username);
  if ((scopeNames && scopeNames?.length > 1) || isEmail) {
    // XXX: Logic used to check if user belongs to one of the configure scope names
    const retrievedUserInfo = await getAuthenticationService().retrieveUserid(
      options.username,
      scopeNames,
    );
    if (isObject(retrievedUserInfo) && retrievedUserInfo?.scopeName) {
      authParams = {
        ...options,
        scopeName: retrievedUserInfo.scopeName,
      };
    }
  }
  const result = await getAuthenticationService().authenticate(authParams);
  let loggedUser;
  if (result.success) {
    loggedUser = await getAuthenticationService().getUserDetails();
  }
  return {
    ...result,
    loggedUser,
  };
};

type Input = LoginByPasswordStartAction;
type Output = LoginByPasswordSuccessAction | LoginByPasswordErrorAction;
export type EpicType = F.Function<
  [ActionsObservable<Input>, Observable<RootState>],
  Observable<Output | BatchAction<Output>>
>;

const createLoginByPassword =
  ({ onSuccess }: EpicCreatorConfig) =>
  (
    action$: ActionsObservable<Input>,
    state$: Observable<RootState>,
  ): Observable<Output | BatchAction<Output>> =>
    action$.pipe(
      ofType(LOGIN_BY_PASSWORD_START),
      tap(() => logger.debug('Trying to authenticate by password')),
      withLatestFrom(
        state$.pipe(map(usernameSelector)),
        state$.pipe(map(recaptchaResponseSelector)),
      ),
      switchMap(
        ([
          {
            payload: {
              username: payloadUsername,
              onLoginSuccess,
              recaptchaResponse: recaptcha,
              scopeNames,
              ...rest
            },
          },
          username,
          recaptchaResponse,
        ]) =>
          from(
            authenticate(
              {
                username: username || payloadUsername || '',
                recaptchaResponse: recaptcha || recaptchaResponse,
                ...rest,
              },
              scopeNames,
            ),
          ).pipe(
            timeout(defaultTimeout),
            tap((data: AuthenticationResponseWithUser) => {
              if (data.success) {
                // XXX: we have to wait 1 sec otherwise the login wont be completed,
                // hopefully this wont bug out any features
                setTimeout(() => {
                  (onSuccess || onLoginSuccess || noop)(data);
                }, 1000);
              }
            }),
            map(
              (data: AuthenticationResponseWithUser) =>
                <BatchAction<Output>>(
                  batchActions([
                    loginByPasswordSuccessAction(data),
                    authenticateSuccessAction(
                      data.loggedUser,
                      data.accessToken,
                    ),
                  ])
                ),
            ),
            catchError(error =>
              concat(
                of(
                  <BatchAction<Output>>(
                    batchActions([
                      loginByPasswordErrorAction(error),
                      authenticateErrorAction(error),
                    ])
                  ),
                ),
              ),
            ),
          ),
      ),
    );

export default createLoginByPassword;
