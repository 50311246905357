// import { createSelector } from 'reselect';
import { NAMESPACE, RootState } from './types';

export const loadingSelector = (state: RootState) => state[NAMESPACE].loading;

export const errorSelector = (state: RootState) => state[NAMESPACE].error;

export const isValidUuidSelector = (state: RootState) =>
  state[NAMESPACE].isValidUuid;

export const emailSentStatusSelector = (state: RootState) =>
  state[NAMESPACE].emailSentStatus;
