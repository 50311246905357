import { BackToLoginAction } from '@auth/view-form-components';
import AuthCard from '@auth/view-form-components/AuthCard';
import { InformUserId } from '@auth/view-recover-password-by-uderid';
import { LoginContentProps } from '@manufacturer/login-ui';
import { useNavigateToLogin } from '@mmw/common-navigation/hooks';
import { getApplicationId } from '@mmw/contextual-config';
import { useEmailSentStatus } from '@mmw/redux-store-auth-api-recover-password/hooks';
import Page from '@mmw/ui-page';
import { ResourcesContextProvider } from '@mmw/ui-providers-resources';
import { ReCaptchaV3ContextProvider } from '@recaptcha/v3';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import React from 'react';

interface LoginPageProps extends LoginContentProps {
  contentStyle?: Style | Style[];
}

const style = css`
  justify-content: center;
`;

const InformUserIdPage: React.FC<LoginPageProps> = ({
  contentStyle = style,
}: LoginPageProps) => {
  const goBack = useNavigateToLogin();
  const assets = useAssets();
  const emailSentStatus = useEmailSentStatus();

  return (
    <Page
      backgroundImageUrl={assets?.loginBackgroundImage}
      backgroundSize="cover"
    >
      <Page.Content contentStyle={contentStyle}>
        <ReCaptchaV3ContextProvider>
          <UI.Container justify="center">
            <ResourcesContextProvider prefixKey={getApplicationId()}>
              <AuthCard
                titleI18n={AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD}
                descI18n={
                  !emailSentStatus
                    ? AUTH_I18N.AUTHENTICATION.SENT_CHANGE_PASSWORD_EMAIL
                    : null
                }
                backActionContent={
                  <BackToLoginAction
                    style={css`
                      text-align: right;
                      text-wrap: nowrap;
                    `}
                    onClick={goBack}
                    textModifiers="capitalize, grayA300"
                    textVariant="caption"
                    useLink
                  />
                }
              >
                <InformUserId />
              </AuthCard>
            </ResourcesContextProvider>
          </UI.Container>
        </ReCaptchaV3ContextProvider>
      </Page.Content>
    </Page>
  );
};

export default InformUserIdPage;
