import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { useParams } from '@mmw/ui-web-libraries-react-router/hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';
import uuidv4 from 'uuid/v4';

import { changePasswordStartAction } from '../actions';

const useChangePassword = () => {
  const language = useLanguage();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  return useCallback(
    (password, onSuccess?: F.Function) => {
      if (!password) return;
      dispatch(changePasswordStartAction(uuid, password, language, onSuccess));
    },
    [dispatch, language, uuid],
  );
};

export const useChangePasswordWithUUID = () => {
  const language = useLanguage();
  const dispatch = useDispatch();
  return useCallback(
    (password?: string, uuid?: string, onSuccess?: () => void) => {
      if (!password) return;
      dispatch(
        changePasswordStartAction(
          uuid || uuidv4(),
          password,
          language,
          onSuccess,
        ),
      );
    },
    [dispatch, language],
  );
};
export default useChangePassword;
