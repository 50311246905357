import { useParams } from '@mmw/ui-web-libraries-react-router/hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { validateChangePasswordUuidStartAction } from '../actions';

const useValidateChangePasswordUuid = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  return useCallback(() => {
    if (!uuid) return;
    dispatch(validateChangePasswordUuidStartAction(uuid));
  }, [dispatch, uuid]);
};

export default useValidateChangePasswordUuid;
