import isNumber from 'lodash/isNumber';
import React, { useEffect, useMemo, useRef } from 'react';
import { animated, useSpring } from 'react-spring';

const AnimatedContainer = animated.div;

interface TempProps {
  hide?: boolean;
  delay?: number;
  transition?: { duration?: number };
  initialHeight?: number;
}

const AnimateHeight: React.FC<React.PropsWithChildren<TempProps>> = ({
  hide,
  delay,
  transition = { duration: 800 },
  children,
  initialHeight = 0,
}: React.PropsWithChildren<TempProps>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [style, animate] = useSpring(() => ({
    height: 0,
    opacity: 0,
    config: transition,
    delay,
  }));
  useEffect(() => {
    animate({
      height:
        !hide && ref?.current && isNumber(ref?.current?.offsetHeight)
          ? ref.current.offsetHeight + initialHeight
          : 0,
      opacity: !hide ? 1 : 0,
    });
  }, [animate, initialHeight, hide]);

  const componentStyle = useMemo(
    () => ({
      overflow: 'hidden',
      // display: hide ? 'none' : 'flex',
      ...style,
    }),
    [style],
  );

  // if (hide) return null;

  return (
    <AnimatedContainer style={componentStyle}>
      <div ref={ref}>{children}</div>
    </AnimatedContainer>
  );
};

export default AnimateHeight;
